import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Contracts from "../../resources/Contracts";
import { Service } from "../../services/Service";
import { actionCreators as AlertStoreActionCreators } from "../../store/AlertStore";
import { handleErrorMessage } from "../../utils/utils";
import CategoryTable from "./CategoryTable";
import "./CategoryManagementContainer.scss";
import AddCategorySection from "./AddCategorySection";
import Texts from "../../resources/Texts";

type CategoryManagementContainerProps = typeof AlertStoreActionCreators & {};

const CategoryManagementContainer: React.FunctionComponent<CategoryManagementContainerProps> = ({
	addErrorAlert,
}) => {
	const [loading, setLoading] = React.useState<boolean>(false);
	const [solutionCategories, setSolutionCategories] = React.useState<Contracts.ICategory[]>([]);

	const loadCategories = React.useCallback(async (): Promise<void> => {
		setLoading(true);

		try {
			const responseItems: Contracts.ICategory[] = await Service.getCategories();

			setSolutionCategories(responseItems);
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}

		setLoading(false);
	}, []);

	React.useEffect(() => {
		loadCategories();
	}, []);

	const handleAddNewCategory = async (category: Contracts.ICategory): Promise<void> => {
		try {
			await Service.addNewCategory(category);

			loadCategories();
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}
	};

	const handleDeleteCategory = async (id: number): Promise<void> => {
		try {
			await Service.deleteCategory(id);

			loadCategories();
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}
	};

	const handleUpdateCategory = async (value: Contracts.ICategory): Promise<void> => {
		try {
			await Service.updateCategory(value);

			loadCategories();
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}
	};

	return (
		<Box>
			<Typography
				variant="h5"
				component="div"
				className="homeView-solutionsLinks-title"
				fontFamily="Poppins"
			>
				{Texts.CategoryManagementView.ViewTitle}
			</Typography>

			{loading ? (
				<Box
					className="categoryManagement-form-container"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<CircularProgress />
				</Box>
			) : (
				<Box
					className="categoryManagement-form-container"
					display="flex"
					flexDirection="column"
				>
					<CategoryTable
						categories={solutionCategories}
						onDelete={handleDeleteCategory}
						onSave={handleUpdateCategory}
					/>

					<AddCategorySection onAdd={handleAddNewCategory} />
				</Box>
			)}
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...AlertStoreActionCreators,
		},
		dispatch
	);
};

export default connect(null, mapDispatchToProps)(CategoryManagementContainer);
