import { Table, TableBody, TableHead, TableRow, TableCell, TableContainer } from "@mui/material";
import * as React from "react";
import * as Contracts from "../../resources/Contracts";
import OpensourceTableRow from "./opensourceTableRow";

type OpensourceTableProps = {
	osDoc: Contracts.IOpensourceFile[];
};

const OpensourceTable: React.FunctionComponent<OpensourceTableProps> = ({
	osDoc,
}) => {
	return (
	<TableContainer>
			<Table width="100%" className="opensourceTable">
      <TableHead>
         <TableRow>
            <TableCell width="150px"><b>Product</b></TableCell>
            <TableCell width="100px"><b>Release</b></TableCell>
            <TableCell width="300px"><b>Title</b></TableCell>
			      <TableCell width="150px"><b>Description</b></TableCell>
            <TableCell width="150px"><b>Release Date</b></TableCell>
            <TableCell width="50px"></TableCell>
        </TableRow>
      </TableHead>
				<TableBody>
					{osDoc.map((item, fileUniqueId) => (
						<OpensourceTableRow
							osDoc={item}
							key={`opensourceTable-item_${fileUniqueId}`}
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default OpensourceTable;
