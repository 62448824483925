import { InputLabel, TextField } from "@mui/material";
import * as React from "react";
import "./FormField.scss";

interface TextFormFieldProps {
	className?: string;
	label?: string;
	value: string;
	onChange: (value: string) => void;
	disabled?: boolean;
	multiline?: boolean;
}

const TextFormField: React.FC<TextFormFieldProps> = (props) => {
	const className: string = props.className ? `formField ${props.className}` : "formField";

	return (
		<>
			{props.label && <InputLabel className="formField-label">{props.label}</InputLabel>}

			<TextField
				multiline={props.multiline}
				className={className}
				variant="filled"
				hiddenLabel
				size="small"
				disabled={props.disabled}
				value={props.value || ""}
				onChange={(e) => props.onChange(e.target.value)}
			/>
		</>
	);
};

export default TextFormField;
