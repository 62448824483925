import { Action, Reducer } from "redux";
import { IFileManagement } from "../resources/Contracts";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface FileManagementState {
	fileData: IFileManagement;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface SetFileAction {
	type: "SET_FILE_MANAGEMENT_FILE";
	payload: IFileManagement;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetFileAction;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
	setFileManagementFile: (file: IFileManagement) =>
		({
			type: "SET_FILE_MANAGEMENT_FILE",
			payload: file,
		} as SetFileAction),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<FileManagementState> = (
	state: FileManagementState | undefined,
	incomingAction: Action
): FileManagementState => {
	if (state === undefined) {
		return {
			fileData: null,
		};
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case "SET_FILE_MANAGEMENT_FILE":
			return { ...state, fileData: action.payload };

		default:
			return state;
	}
};
