/*
  CompanyPicker component (tsx)
  --------------------------------------------------------------------------------
  - Reads list of companies from the provided url (the SID master companies list)
  - Allows user to select multiple companies
  - Updates ChannelAccess table row for channelUniqueId, removes all rows for channel+company then adds a row per company
  ------------------------------
    This version:  17/07/2024
*/
import * as React from 'react';
import { useEffect, useState } from 'react';

// mui
import Box from '@mui/material/Box';
import { Alert, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';

// custom
import CustomTextField from '../CustomTextField';
import { IChannelCompany } from '../../../resources/Contracts';

type CompanyPickerProps = {
  channelUniqueId: string,
  targetCompanies: IChannelCompany[],
  setTargetCompanies,
}

//export default function CompanyPicker( selectedChannelUniqueId, { setTargetCompanies } ) 
const CompanyPicker = (props: CompanyPickerProps) => 
{
  const apiGetCompaniesUrl="/api/companies/getcompanies";
  const apiGetChannelCompaniesUrl = "/api/Channel/company/permissions/";   // props must pass a valid channelUniqueId if this component is used for Update (not needed for Add)
  const [searchTerm, setSearchTerm] = useState("");                                     // search box contents
  const [data, setData] = useState<IChannelCompany[]>([]);                            // data read from companyApiUrl
  const [pickedCompanies, setPickedCompanies] = useState<IChannelCompany[]>([]);  // selected companies returned to caller

  let searchTimeout: NodeJS.Timeout;
  
  const columns: GridColDef<(IChannelCompany)>[] = [
    {
      field: 'companyName',
      headerName: 'Company',
      flex: 1,
      editable: false,
    }];

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>): void 
  {
    clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
      
      // check for null/undefined
      let searchTextBoxValue = event.target.value || "";
      
      // append the ?searchTerm query string only if search text field contains something
      if(searchTextBoxValue !== "")
        setSearchTerm("?searchTerm="+searchTextBoxValue);
      else 
        setSearchTerm("");   // user cleared the textbox - remove query string; web service does not accept empty/null ?searchTerm=
    }, 500);
  }

  const handleRowClickAdd: GridEventListener<'rowClick'> = (params) => {
    const concatArray: IChannelCompany[] = [...(pickedCompanies || []), ...([params.row] || [])];
    const uniqueArray = Array.from(
        new Set(concatArray.map(item => item.id))
    ).map(id => {
      return concatArray.find(item => item.id === id);
    });
    setPickedCompanies(uniqueArray);
    props.setTargetCompanies(uniqueArray);
  };

  const handleRowClickRemove: GridEventListener<'rowClick'> = (params) => {
    const rowToDelete : number = params.row.id;
    if(pickedCompanies?.length !== 0)
    {
      let filteredPick = pickedCompanies.filter((row) => row.id !== rowToDelete);
      setPickedCompanies(filteredPick);
      props.setTargetCompanies(filteredPick);
    }
  };

  useEffect(() => {
    fetch(apiGetCompaniesUrl+searchTerm)
      .then((response) => response.json())
      .then((data) => setData( data ))
      .catch((error) => console.error('Error fetching companies data:', error));
  }, [apiGetCompaniesUrl, searchTerm]);

  useEffect(() => {
    if (props.targetCompanies.length !== 0) {
      setPickedCompanies(props.targetCompanies);
    }

    if(props.channelUniqueId !== '' && props.targetCompanies.length === 0) {
      fetch(apiGetChannelCompaniesUrl + props.channelUniqueId)
          .then((response) => response.json())
          .then((data) => {
            setPickedCompanies(data);
            //props.setTargetCompanies(data);
          })
          .catch((error) => console.error('Error fetching companies data:', error));
    }
  }, [apiGetChannelCompaniesUrl]);

  return (
    <Box sx={{ height: '700px', width: '100%' }}>
      <Stack direction="row" spacing={3} sx={{ mb: 4 }}>
        <CustomTextField changeHandler={handleSearchChange} label={"Search..."} name={"searchTermText"}/>
    </Stack>
      <Stack direction="row" spacing={1} >
        <Box sx={{ height: '400px', width: '50%' }}>
          <div style={{backgroundColor: 'powderblue', padding: '8px', display: 'flex', justifyContent: 'center'}}>
            <Typography><b>Available Companies</b></Typography>
          </div>
          <DataGrid
            rows={data}
            columns={columns}
            rowSelection
            onRowClick={handleRowClickAdd} {...data}
            columnHeaderHeight={0}
            disableColumnResize={true}
          />
        </Box>
        <Box sx={{ height: '400px', width: '50%' }}>
          <div style={{backgroundColor: 'powderblue', padding: '8px', display: 'flex', justifyContent: 'center'}}>
            <Typography><b>Selected Companies</b></Typography>
          </div>
          <DataGrid
            rows={pickedCompanies}
            columns={columns}
            rowSelection
            onRowClick={handleRowClickRemove} {...pickedCompanies}
            columnHeaderHeight={0}
          />
        </Box>
      </Stack>
    </Box>
  );
}
export default CompanyPicker