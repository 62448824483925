import { Container } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import "./CategoryManagementView.scss";
import CategoryManagementContainer from "../../components/categoryManagement/CategoryManagementContainer";

interface CategoryManagementViewProps {}

const CategoryManagementView: React.FunctionComponent<CategoryManagementViewProps> = (props) => {
	return (
		<Box className="categoryManagement-view" display="flex" flexDirection="column">
			<Container>
				<CategoryManagementContainer />
			</Container>
		</Box>
	);
};

export default CategoryManagementView;
