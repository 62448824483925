import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import { Box, CircularProgress, Collapse, IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { ISolutionTreeItem } from "../../resources/Contracts";
import { SolutionsGroup } from "../../resources/Enums";
import { Routing } from "../../resources/Routes";
import Texts from "../../resources/Texts";
import { Service } from "../../services/Service";
import { ApplicationState } from "../../store";
import { actionCreators as AlertStoreActionCreators } from "../../store/AlertStore";
import * as SelectedSolutionStore from "../../store/SelectedSolution";
import { handleErrorMessage } from "../../utils/utils";
import DeleteConfirmationDialog from "../dialogs/DeleteConfirmationDialog";
import AddCategoryInput from "../shared/AddCategoryInput";
import RoleRestrictedComponent from "../shared/formControls/RoleRestrictedComponent";
import "./SolutionsBrowserTree.scss";

type SolutionsBrowserTreeProps = SelectedSolutionStore.SelectedSolutionState &
	typeof SelectedSolutionStore.actionCreators &
	typeof AlertStoreActionCreators & {
		solutionId: number,
		solutionGroup?: string,
		defaultSelectedId?: string
	};

const SolutionsBrowserTree: React.FC<SolutionsBrowserTreeProps> = ({
	solutionId,
	solutionGroup,
	defaultSelectedId,
	setSelectedGroup,
	setSelectedGroupId,
	setSelectedSolution,
	selectedSolutionsTabId,
	setSelectedSolutionTabId,
	addErrorAlert,
}) => {
	//Tree items displayed
	const [items, setItems] = React.useState<ISolutionTreeItem[]>([]);
	// Tree nodes with expanded children
	const [expandedNodes, setExpandedNodes] = React.useState<string[]>([]);
	// Selected tree item id
	const [selectedSolutionId, setSelectedSolutionId] = React.useState<string>(null);
	// Tree item data loading
	const [loading, setLoading] = React.useState<boolean>(false);
	// Is add folder to root input visible
	const [addInputVisible, setAddInputVisible] = React.useState<boolean>(false);
	// Id of tree item with add folder input active
	const [visibleAddNodeId, setAddVisibleNodeId] = React.useState<string>(null);
	// Id of tree item with add folder input active
	const [visibleEditNodeId, setEditVisibleNodeId] = React.useState<string>(null);
	// Is Delete dialog visible
	const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);
	// Item to be deleted after delete dialog is confirmed
	const [itemToBeDeleted, setItemToBeDeleted] = React.useState<ISolutionTreeItem>(null);

	React.useEffect(() => {
		return () => {
			setSelectedSolution(null);
			setSelectedSolutionTabId(null);
		};
	}, [setSelectedSolution, setSelectedSolutionTabId]);

	const loadItems = React.useCallback(
		async (defaultSelectedId?: string): Promise<void> => {
			setLoading(true);

			try {
				let responseItem: ISolutionTreeItem;

				if (selectedSolutionsTabId) {
					responseItem = await Service.getTabItem(selectedSolutionsTabId);
				} else if (
					solutionGroup === SolutionsGroup.EOL ||
					solutionGroup === SolutionsGroup.FieldNotices 
				) {
					responseItem = await Service.getFoldersStructure(solutionGroup, false);
				}

				if (defaultSelectedId && responseItem) {
					const handleDefaultExpandedNodes = (
						treeItem: ISolutionTreeItem,
						parentIds: string[]
					): void => {
						const parentIdsCopy: string[] = parentIds.slice();

						if (treeItem.id === defaultSelectedId) {
							setExpandedNodes(parentIdsCopy);
						} else if (treeItem.nodes?.length > 0) {
							parentIdsCopy.push(treeItem.id);
							treeItem.nodes.forEach((n) =>
								handleDefaultExpandedNodes(n, parentIdsCopy)
							);
						}
					};

					const findNodeInTree = (
						nodes: ISolutionTreeItem[],
						nodeId: string
					): ISolutionTreeItem => {
						let stack: ISolutionTreeItem[] = nodes.slice();

						let result: ISolutionTreeItem;

						while (stack.length > 0) {
							result = stack.pop();

							if (result.id === nodeId) {
								return result;
							} else if (result.nodes?.length > 0) {
								result.nodes.forEach((child) => stack.push(child));
							}
						}

						return null;
					};

					handleDefaultExpandedNodes(responseItem, []);
					setSelectedSolutionId(defaultSelectedId);
					setSelectedSolution(findNodeInTree(responseItem.nodes, defaultSelectedId));
				}

				setItems(responseItem?.nodes);
			} catch (error) {
				addErrorAlert(handleErrorMessage(error));
				setItems([]);
			}

			setLoading(false);
		},
		[selectedSolutionsTabId, solutionGroup, setSelectedSolution, addErrorAlert]
	);

	React.useEffect(() => {
		loadItems(defaultSelectedId);
		setSelectedGroupId(solutionId);
		setSelectedGroup(solutionGroup);
		
		setSelectedSolutionId(null);
	}, [selectedSolutionsTabId, defaultSelectedId, loadItems, setSelectedGroupId, solutionId, setSelectedGroup, solutionGroup]);

	const addFolder = async (folderId: string, folderName: string): Promise<void> => {
		setLoading(true);

		try {
			await Service.addNewFolder(folderId, folderName);

			loadItems();
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}

		setLoading(false);
	};

	const editFolder = async (folderId: string, folderName: string): Promise<void> => {
		setLoading(true);

		try {
			await Service.editFolder(folderId, folderName);

			loadItems();
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}

		setLoading(false);
	};

	const deleteFolder = async (folderId: string): Promise<void> => {
		setLoading(true);

		try {
			await Service.deleteFolder(folderId);

			loadItems();
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}

		setLoading(false);
	};

	const handleNodeSelect = (event: React.SyntheticEvent, nodeId: Array<string> | string) => {
		setSelectedSolutionId(nodeId as string);
	};

	const handleExpandedNodes = (nodeId: string) => {
		let expandedNodesCopy: string[] = expandedNodes.slice();

		if (expandedNodesCopy.some((en) => en === nodeId)) {
			expandedNodesCopy = expandedNodesCopy.filter((n) => n !== nodeId);
		} else {
			expandedNodesCopy.push(nodeId);
		}

		setExpandedNodes(expandedNodesCopy);
	};

	const handleNodeClick = (node: ISolutionTreeItem) => {
		setSelectedSolution(node);

		if (node.nodes?.length > 0) {
			handleExpandedNodes(node.id);
		}
	};

	const handleAddNewRootCategoryClick = (): void => {
		setAddInputVisible(true);
	};

	const handleAddNewCategoryClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		nodeId: string
	): void => {
		event.stopPropagation();

		setAddVisibleNodeId(nodeId);
		setEditVisibleNodeId(null);
	};

	const handleEditCategoryClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		nodeId: string
	): void => {
		event.stopPropagation();

		setAddVisibleNodeId(null);
		setEditVisibleNodeId(nodeId);
	};

	const handleDeleteClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		node: ISolutionTreeItem
	): void => {
		event.stopPropagation();
		setItemToBeDeleted(node);
		setDeleteDialogOpen(true);
	};

	const handleNewRootCategoryAddClick = (folderName: string): void => {
		if (!selectedSolutionsTabId)
			addFolder(`${solutionId}`, folderName);
		else
			addFolder(selectedSolutionsTabId, folderName);

		setAddInputVisible(false);
	};

	const handleNewCategoryAddClick = (folderId: string, folderName: string): void => {
		addFolder(folderId, folderName);

		setAddVisibleNodeId(null);
	};

	const handleEditCategoryAddClick = (folderId: string, folderName: string): void => {
		editFolder(folderId, folderName);

		setEditVisibleNodeId(null);
	};

	const handleNewRootCategoryCancelClick = (): void => {
		setAddInputVisible(false);
	};

	const handleNewCategoryCancelClick = (): void => {
		setAddVisibleNodeId(null);
	};

	const handleEditCategoryCancelClick = (): void => {
		setEditVisibleNodeId(null);
	};

	const handleDeleteDialogClose = (): void => {
		setDeleteDialogOpen(false);
		setItemToBeDeleted(null);
	};

	const handleDeleteDialogConfirm = (): void => {
		const itemId: string = itemToBeDeleted.id;

		deleteFolder(itemId);
	};

	const renderTreeItems = (node: ISolutionTreeItem) => {
		let className: string = expandedNodes.some((sn) => sn === node.id)
			? "tree-item expanded"
			: "tree-item";

		if (node.id === selectedSolutionId) {
			className = `${className} selected`;
		}

		let label: React.ReactNode;

		label = (
			<Box className="tree-item-container" display="flex" flexDirection="column">
				<Box
					className="tree-item-label-wrapper"
					display="flex"
					alignItems="center"
					justifyContent="space-between"
				>
					<Box className="tree-item-label" display="flex" alignItems="center">
						{node.text}
					</Box>

					<RoleRestrictedComponent enabledRoles={["Engineer"]}>
						<Box className="tree-item-actions" display="flex" alignItems="center">
							<Tooltip title={Texts.SolutionsView.Browser.TreeItemTooltips.AddNew}>
								<IconButton
									className="tree-item-action"
									onClick={(event) => handleAddNewCategoryClick(event, node.id)}
									disableRipple
								>
									<AccountTreeIcon className="icon" />
								</IconButton>
							</Tooltip>

							<Tooltip title={Texts.SolutionsView.Browser.TreeItemTooltips.Upload}>
								<Link
									className="tree-item-action"
									to={Routing.getFileManagementUrl({
										parentId: node.id,
										from: solutionGroup,
										fromId: solutionId,
										prevTabId: selectedSolutionsTabId,
									})}
								>
									<UploadFileIcon className="icon" />
								</Link>
							</Tooltip>

							<Tooltip title={Texts.SolutionsView.Browser.TreeItemTooltips.Edit}>
								<IconButton
									className="tree-item-action"
									onClick={(event) => handleEditCategoryClick(event, node.id)}
									disableRipple
								>
									<EditIcon className="icon" />
								</IconButton>
							</Tooltip>

							<Tooltip title={Texts.SolutionsView.Browser.TreeItemTooltips.Delete}>
								<IconButton
									className="tree-item-action"
									onClick={(event) => handleDeleteClick(event, node)}
									disableRipple
								>
									<DeleteIcon className="icon" />
								</IconButton>
							</Tooltip>
						</Box>
					</RoleRestrictedComponent>
				</Box>

				<Collapse in={visibleAddNodeId === node.id}>
					<AddCategoryInput
						onAdd={(name) => handleNewCategoryAddClick(node.id, name)}
						onCancel={handleNewCategoryCancelClick}
					/>
				</Collapse>

				<Collapse in={visibleEditNodeId === node.id}>
					<AddCategoryInput
						primaryButtonText={Texts.Buttons.Edit}
						defaultValue={node.text}
						onAdd={(name) => handleEditCategoryAddClick(node.id, name)}
						onCancel={handleEditCategoryCancelClick}
					/>
				</Collapse>
			</Box>
		);

		return (
			<TreeItem
				id={node.id}
				key={node.id}
				nodeId={node.id}
				label={label}
				onClick={() => handleNodeClick(node)}
				className={className}
			>
				{Array.isArray(node.nodes) && node.nodes?.length > 0
					? node.nodes.map((subNode) => renderTreeItems(subNode))
					: null}
			</TreeItem>
		);
	};

	return loading ? (
		<Box display="flex" flex={1} justifyContent="center">
			<CircularProgress />
		</Box>
	) : (
		<Box
			className="solutionsBrowser-tree-container"
			display="flex"
			flex={1}
			flexDirection="column"
		>
			<RoleRestrictedComponent enabledRoles={["Engineer"]}>
				<Box
					className="solutionsBrowser-tree-addInput-wrapper"
					display="flex"
					flexDirection="column"
					flex={[1, 0]}
				>
					<Box
						className="solutionsBrowser-tree-addInput-icon-wrapper"
						display="flex"
						flex={[1, 0]}
						justifyContent="flex-end"
						alignItems="center"
					>
						<Box
							className="solutionsBrowser-tree-addInput-control"
							display="flex"
							alignItems="center"
							onClick={handleAddNewRootCategoryClick}
						>
							<AccountTreeIcon className="icon" />
							{Texts.SolutionsView.Tabs.AddNewCategory}
						</Box>
					</Box>

					<Collapse
						in={addInputVisible}
						className="solutionsBrowser-tree-addInput-collapse"
						unmountOnExit
					>
							<AddCategoryInput
								onAdd={handleNewRootCategoryAddClick}
							onCancel={handleNewRootCategoryCancelClick}
						/>
					</Collapse>
				</Box>
			</RoleRestrictedComponent>

			<TreeView
				className="solutionsBrowser-tree"
				defaultCollapseIcon={<ArrowDropDownIcon className="icon icon-arrow-down" />}
				defaultExpandIcon={<ArrowRightIcon className="icon icon-arrow-right" />}
				onNodeSelect={handleNodeSelect}
				expanded={expandedNodes}
				selected={selectedSolutionId ? [selectedSolutionId] : []}
			>
				{items?.map((node) => renderTreeItems(node))}
			</TreeView>

			<DeleteConfirmationDialog
				itemName={itemToBeDeleted?.text}
				open={deleteDialogOpen}
				close={handleDeleteDialogClose}
				onDelete={handleDeleteDialogConfirm}
			/>
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...SelectedSolutionStore.actionCreators,
			...AlertStoreActionCreators,
		},
		dispatch
	);
};

export default connect(
	(state: ApplicationState) => state.selectedSolutions,
	mapDispatchToProps
)(SolutionsBrowserTree);
