import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { CurrentUserState } from "../../../store/CurrentUser";

type LoggedInCustomerRestrictedComponentProps = CurrentUserState & { 
};

const LoggedInCustomerRestrictedComponent: React.FC<React.PropsWithChildren<LoggedInCustomerRestrictedComponentProps>> = (props) => {

	// This component will return the child component if there's a logged in CUSTOMER
	if (typeof props.user?.loginname !== 'undefined')
	{
		let domain = props.user.loginname.split("@");
		if(domain[1].toString().toUpperCase() != "SYNAMEDIA.COM")
			return <>{props.children}</>;
	}
	return null;
};

export default connect((state: ApplicationState) => state.currentUser)(LoggedInCustomerRestrictedComponent);
