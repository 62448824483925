/*
  NodePicker component (tsx)
  --------------------------------------------------------------------------------
  - Reads Navigation table from supplied apiUrl
  - Displays tree structure and allows selection of a node
  - Sets parent state (targetNode) to the selected node
*/
import * as React from 'react';

// mui
import Box from '@mui/material/Box';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function NodePicker( { setTargetNode } )
{
  const apiUrl = "/api/Channel/folders/synamedia/false";
  const [data, setData] = useState([]);
 
  useEffect(() => {
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, [apiUrl]);

    function findNodeById(tree, id) {
        // Check if the current node matches the id
        if (tree.id === id) {
            return tree;
        }

        // If there are children, iterate through them and search recursively
        if (tree.children && tree.children.length > 0) {
            for (const child of tree.children) {
                const result = findNodeById(child, id);
                if (result) {
                    return result;
                }
            }
        }

        // Return null if the node with the id is not found
        return null;
    }

  const handleItemSelectionToggle = (
    event: React.SyntheticEvent,
    itemId: string,
    isSelected: boolean,
  ) => {

    if (isSelected) {
      const node = findNodeById(data[0], itemId);
      const isLeaf = node?.children ? node.children.length === 0 : false;
      setTargetNode(itemId, isLeaf);
    }
  };

  return (
    <Box sx={{ height: '100%', width: '100%', overflow: 'auto' }}>
          <RichTreeView
            items={data}
            onItemSelectionToggle={handleItemSelectionToggle}
            slots={{
                endIcon: CloseSquare,
            }}
          />
    </Box>
  );
}

function CloseSquare(props: SvgIconProps) {
    return (
        <SvgIcon
            className="close"
            fontSize="inherit"
            style={{ width: 14, height: 14, color: 'blue' }}
            {...props}
        >
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    );
}
