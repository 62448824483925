import { Box } from "@mui/system";
import * as React from "react";
import { ISolutionTreeItem } from "../../resources/Contracts";
import VersionRecord from "./VersionRecord";

interface VersionRecordListProps {
	fileNodes: ISolutionTreeItem[];
	onDelete: (id: string) => void;
}

const VersionRecordList: React.FC<VersionRecordListProps> = (props) => {
	return (
		<Box display="flex" flexDirection="column">
			{props.fileNodes?.map((record) => (
				<VersionRecord record={record} key={record.id} onDelete={props.onDelete} />
			))}
		</Box>
	);
};

export default VersionRecordList;
