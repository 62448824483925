import { Box } from "@mui/system";
import * as React from "react";
import { Link } from "react-router-dom";
import { Routing } from "../../resources/Routes";
import Texts from "../../resources/Texts";
import "./ManagementToolsButton.scss";

type ManagementToolsButtonProps = {};

/*
	option removed as too dangerous
	<Link className="header-link link" to={Routing.getCategoryManagementViewUrl()}>	{Texts.Navigation.Categories} </Link>
*/

const ManagementToolsButton: React.FC<ManagementToolsButtonProps> = () => {
	return (
		<Box
			className="header-link-box header-managementTools"
			display="flex"
			alignItems="center"
			position="relative"
		>
			{Texts.Navigation.ManagementTools}

			<Box
				className="managementTools-menu"
				display="flex"
				flexDirection="column"
				alignItems="left"
				position="absolute"
			>
				<Link className="header-link link" to={Routing.getNewFileViewUrl()}>
					{Texts.Navigation.AddNewFile}
				</Link>
				<Link className="header-link link" to={Routing.getChannelManagementViewUrl()}>
					{Texts.Navigation.ChannelAdmin}
				</Link>
			</Box>
		</Box>
	);
};

export default ManagementToolsButton;
