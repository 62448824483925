import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Contracts from "../../resources/Contracts";
//import { Service } from "../../services/Service";
import { actionCreators as AlertStoreActionCreators } from "../../store/AlertStore";
import { handleErrorMessage } from "../../utils/utils";
import "./ChannelManagementContainer.scss";
import Texts from "../../resources/Texts";
import { IChannel, IChannelCompany } from "../../resources/Contracts";
import ChannelList from "./ChannelList/ChannelList";
import AddChannelModal from "./AddChannel/AddChannelModal";
import DeleteChannelModal from "./DeleteChannel/DeleteChannelModal";
import ViewChannelModal from "./ViewChannel/ViewChannelModal";
import ModifyChannelModal from "./ModifyChannel/ModifyChannelModal";
//import ManageChannelAccessKeysModal from "./ManageChannelKeys/ManageChannelKeysModal";
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import clsx from 'clsx';
import InfoIcon from '@mui/icons-material/Info';

type ChannelManagementContainerProps = typeof AlertStoreActionCreators & {};

const Backdrop = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'base-Backdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')({
  fontFamily: 'IBM Plex Sans, sans-serif',
  fontWeight: 500,
  textAlign: 'start',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  gap: '8px',
  overflow: 'hidden',
  backgroundColor: '#fff', // Static background color
  borderRadius: '8px',
  border: `1px solid white`, // Static border color
  boxShadow: '0 4px 12px rgb(0 0 0 / 0.2)', // Static shadow
  color: 'black', // Static text color

  '& .modal-title': {
    margin: 0,
    lineHeight: '1.5rem',
    marginBottom: '8px',
  },

  '& .modal-description': {
    margin: 0,
    lineHeight: '1.5rem',
    fontWeight: 400,
    color: '#303740', // Static description color
    marginBottom: '4px',
  },

	'&:focus': {
		outline: 'none', // Removes the default blue focus outline
	},
});

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
	margin: auto;
`;

const TriggerButton = styled('button')({
	fontFamily: 'IBM Plex Sans, sans-serif',
	fontWeight: 600,
	width: 100,
	marginRight: '16px',
	fontSize: '0.875rem',
	lineHeight: 1.5,
	padding: '8px 16px',
	transition: 'all 150ms ease',
	cursor: 'pointer',
	background: '#3380FF',
	border: '1px solid #3380FF',
	color: 'white',
	margin: 'auto',

	'&:hover': {
		boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.35)',
	}
});

const ChannelManagementContainer: React.FunctionComponent<ChannelManagementContainerProps> = ({ addErrorAlert, }) => {
	const [channels, setChannels] = React.useState<Contracts.IChannel[]>([]);
	const [helpOpen, setHelpOpen] = React.useState(false);

	// Sub-component selections
	const [selectedTreeNode, setSelectedTreeNode] = React.useState(0);
	const [selectedCompanies, setSelectedCompanies] = React.useState<IChannelCompany[]>([]);
	const [selectedChannel, setSelectedChannel] = React.useState("");

	interface ChannelProps {
		selectedChannel;
		selectedTreeNode;
		selectedCompanies;
	}

	const handleHelpOpen = () => {setHelpOpen(true)};
	const handleHelpClose = () => setHelpOpen(false);
	
	return (
		<Box className="channelManagement-form-container" display="flex" flexDirection="column">
		<Box>
			<Typography
				variant="h5"
				component="div"
				className="channelManagement-form-title"
				fontFamily="Poppins"
			>
				{Texts.ChannelManagementView.ViewTitle}
				<InfoIcon style={{float: "right", color: "#3380FF", paddingTop: '4px', cursor: "pointer"}} onClick={handleHelpOpen}>Help</InfoIcon>
			</Typography>


			<Box
				className="channelManagement-form-container"
					display="flex"
					flexDirection="column"
				>
				<Box
					className="channelManagement-form-container"
					display="flex"
					flexDirection="row"
					sx={{marginBottom: "16px"}}
				>
        		<AddChannelModal/>
				{ selectedChannel !== "" && <ViewChannelModal selectedChannelUniqueId={selectedChannel} /> }
				{ selectedChannel !== "" && <ModifyChannelModal selectedChannelUniqueId={selectedChannel} /> }
				{ selectedChannel !== "" && <DeleteChannelModal selectedChannelUniqueId={selectedChannel} /> }
				</Box>
				<ChannelList setSelectedChannel={setSelectedChannel}/>
			</Box>
		</Box>
			<Modal
				open={helpOpen}
				onClose={handleHelpClose}
				slots={{ backdrop: StyledBackdrop }}
				sx={{ width: 900 }}
			>
				<ModalContent>
					<div className="modal-description">
						<h1 style={{textAlign: 'center'}}>Automatic Software Download</h1>
						<p>This feature enables customer devices to retrieve software updates automatically from the <i>Software Download Portal</i>.</p>
						<p>A download channel must first be created by pressing the '<i>Create</i>' button.
					       You must then specify a channel name and a description.</p>
					    <p>A download channel is targeted to a particular leaf category in the Software Download Portal's software and documentation tree.
						   Everything under that leaf category is available for download, so it is recommended that you create an explicit category that will contain all assets that  can be downloaded automatically.
							To specify the leaf category (Shared Path) to be associated with the download channel, click on ‘<i>Select Path</i>’.</p>
						<p>You can also specify which companies have access to the channel, click on ‘<i>Select Companies</i>’.
					       The permissions you assign to assets are also obeyed. For example, you can give access to a channel to company A and company B,
							but if you’ve specified that an asset in that channel is only accessible to company A then company B will not have access to it via the download APIs.</p>
                        <p>In order for the customer's device to access the channel, they must create access keys. This option is available under the <i>Customer Tools - Download Channels</i> menu for an external (customer) login.
							These must be copied to the device so that the download API is accessible.
							You can see which customers have created keys by viewing a download channel when logged in as a Synamedia user.</p>
					</div>
					<TriggerButton onClick={handleHelpClose}>Close</TriggerButton>
				</ModalContent>
			</Modal>
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...AlertStoreActionCreators,
		},
		dispatch
	);
};

export default connect(null, mapDispatchToProps)(ChannelManagementContainer);
