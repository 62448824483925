import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
	Collapse,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
} from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ISolutionTreeItem } from "../../resources/Contracts";
import { actionCreators as AlertStoreActionCreators } from "../../store/AlertStore";
import "./BrowserDetailTable.scss";
import VersionRecordList from "./VersionRecordList";

type RowProps = {
	row: ISolutionTreeItem;
	onDelete: (id: string) => void;
};

const Row: React.FC<RowProps> = (props) => {
	const { row } = props;
	const [open, setOpen] = React.useState(false);

	const className: string = open ? "browser-detail-item" : "browser-detail-item closed";

	const rowText: string = row.text === "00.00.00" ? "Latest" : row.text;

	return (
		<>
			<TableRow
				sx={{ "& > *": { borderBottom: "unset" } }}
				onClick={() => setOpen(!open)}
				className={className}
			>
				<TableCell width={15}>
					<IconButton aria-label="expand row" size="small" disableRipple>
						{open ? (
							<ArrowDropDownIcon className="icon" />
						) : (
							<ArrowRightIcon className="icon" />
						)}
					</IconButton>
				</TableCell>
				<TableCell>{rowText}</TableCell>
			</TableRow>
			<TableRow className="browser-detail-item-content">
				<TableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={6}
					className="browser-detail-item-content-cell"
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<VersionRecordList fileNodes={row.nodes} onDelete={props.onDelete} />
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

type BrowserDetailTableProps = typeof AlertStoreActionCreators & {
	detailNodes: ISolutionTreeItem[];
	onDelete: (id: string) => void;
};

const BrowserDetailTable: React.FC<BrowserDetailTableProps> = (props) => {
	let orderedNodes: ISolutionTreeItem[] = [];

	if (props.detailNodes) {
		orderedNodes = props.detailNodes.filter((n) => n.text === "00.00.00");
		orderedNodes = orderedNodes.concat(
			props.detailNodes
				.filter((n) => n.text !== "00.00.00")
				.sort((a, b) => {
					if (a.text > b.text) return -1;

					if (a.text < b.text) return 1;

					return 0;
				})
		);

		orderedNodes = orderedNodes.filter((n) => n.nodes.length > 0);
	}

	return (
		<TableContainer>
			<Table aria-label="collapsible table">
				<TableBody>
					{orderedNodes.map((row) => (
						<Row key={row.id} row={row} onDelete={props.onDelete} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...AlertStoreActionCreators,
		},
		dispatch
	);
};

export default connect(null, mapDispatchToProps)(BrowserDetailTable);
