import * as React from 'react';
import clsx from 'clsx';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import CustomTextField from '../CustomTextField';
import CompanyPickerModal from '../CompanyPickerModal';
import { IAccessKey, IChannel, IChannelCompany, IChannelPermission } from '../../../resources/Contracts';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import NodePickerModal from '../NodePickerModal';
import { Routing } from '../../../resources/Routes';

export default function ModifyChannelModal({selectedChannelUniqueId}) {
  const [open, setOpen] = React.useState(false);
  const [companyDeletedModalOpen, setCompanyDeletedModalOpen] = React.useState(false);

  // state related to the new channel
  const [channelName, setChannelName] = React.useState("");
  const [channelDescription, setChannelDescription] = React.useState("");
  const [targetNode, setTargetNode] = React.useState(0);  // the id of the node for this channel
  const [targetCompanies, setTargetCompanies] = React.useState<IChannelCompany[]>([]);

  // UI - may be unused
  const [channelIsEnabled, setChannelIsEnabled] = React.useState("");
  const [channelIsBlocked, setChannelIsBlocked] = React.useState("");
  const [channelUniqueId, setChannelUniqueId] = React.useState("");
  const [channelSharedPath, setChannelSharedPath] = React.useState("");
  const [channelNavigationId, setChannelNavigationId] = React.useState(0);

  // Data state
  const [channelPermissions, setChannelPermissions] = React.useState<IChannelPermission[]>([]);
  const [channelCompanies, setChannelCompanies] = React.useState<IChannelCompany[]>([]);
  const [channelAccessKeys, setChannelAccessKeys] = React.useState<IAccessKey[]>([]);
  const [channels, setChannels] = React.useState<IChannel[]>([]);
  const [companiesToRemove, setCompaniesToRemove] = React.useState([]);

   // API urls
   const apiUpdateChannel = "/api/Channel";  // the url for the PATCH request
   const apiGetChannel = `/api/Channel/${selectedChannelUniqueId}`;
   const apiGetAccessKeys =  `/api/Channel/accesskeys/${selectedChannelUniqueId}`;
   const apiGetChannelPermissions = `/api/Channel/permissions/${selectedChannelUniqueId}`;
   const apiGetChannelCompanies = `/api/Channel/company/permissions/${selectedChannelUniqueId}`;
   const apiResolveFriendlyNode = "/api/Channel/navigation/friendlypath";
   //const apiResolveCompanyNames = '/api/Channel/augment/companies';

   //React.useEffect(() => {
   // fetch(`${apiResolveFriendlyNode}/${targetNode}`)
   //   .then((response) => response.text())
   //   .then((data) => setTargetNodeFriendly(data))
   //   .catch((error) => console.error('Error fetching data:', error));
  //}, [targetNode]);

  React.useEffect(() => {
    fetch(`${apiResolveFriendlyNode}/${targetNode}`)
     .then((response) => response.text())
     .then((data) => setChannelSharedPath(data))
     .catch((error) => console.error('Error fetching data:', error));
  },[targetNode]);

  async function fetchChannel() {
    const response = await fetch(apiGetChannel);
    if(response.ok)
    {
      const channel = await response.json();
      return channel[0];
    }
    return [];
  }
 
  async function fetchChannelPermissions() {
    const response = await fetch(apiGetChannelPermissions);
    if(response.ok)
    {
      const channelPermissions = await response.json();
      return channelPermissions;
    }
    return [];
  }
 
  async function fetchChannelCompanies() {
    const response = await fetch(apiGetChannelCompanies);
    if(response.ok)
    {
      const channelCompanies = await response.json();
      return channelCompanies.sort((a, b) => b.companyName.toLowerCase() < a.companyName.toLowerCase());
    }
     return [];
   }
 
  async function fetchChannelAccessKeys() {
    const response = await fetch(apiGetAccessKeys);
    if(response.ok)
    {
      const channelPermissions = await response.json();
      return channelPermissions;
    }
    return [];
  }

  

  const handleOpen = async () => {
  const getChannel = await fetchChannel();
  const getAccessKeys = await fetchChannelAccessKeys();
  const getChannelPermissions = await fetchChannelPermissions();
  const getChannelCompanies = await fetchChannelCompanies();

   
   if(getChannel !== null) {
     setChannelName(getChannel.name);
     setChannelDescription(getChannel.description);
     setChannelUniqueId(getChannel.channelUniqueId);
     setChannelIsEnabled(getChannel.isEnabled);
     setChannelIsBlocked(getChannel.isBlocked);
     setChannelNavigationId(getChannel.navigationId);
     setChannelSharedPath(getChannel.resolvedNavigationNode);
     setTargetNode(getChannel.navigationId);
     
     console.log(getChannel);
   }
   else{
    console.log("getChannel is null");
   }
   
   // set data state
   if(getAccessKeys !== null) setChannelAccessKeys(getAccessKeys);
   if(getChannelPermissions != null) { 
    setChannelPermissions(getChannelPermissions);
    console.log("IChannelAccess objects for this channel")
    console.log(getChannelPermissions);
  }
   if(getChannelCompanies != null) { 
    setChannelCompanies(getChannelCompanies); 
    setTargetCompanies(getChannelCompanies);
    console.log("ICompany objects for this channel");
    console.log(getChannelCompanies);
   }
    setOpen(true);
  }
  const handleClose = () => setOpen(false);

  const handleModify = async () => 
  {
    const removedCompanies = channelCompanies.filter(entry => !targetCompanies.find(entryTarget => entryTarget.id === entry.id));

    if (removedCompanies.length > 0) {
      setCompaniesToRemove(removedCompanies.map(entry => entry.companyName));
      setCompanyDeletedModalOpen(true);
      return;
    }
    setCompaniesToRemove([]);
    await saveModifiedChannel();
  }

  const handleCloseReset = () =>
  {
    setTargetNode(0);
    setTargetCompanies([]);
    setCompaniesToRemove([]);
    setOpen(false);
  }

  function handleNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setChannelName(event.target.value);
  }

  function handleDescriptionChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setChannelDescription(event.target.value);
  }

  const companyDeletedModal =
      <Modal
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={companyDeletedModalOpen}
          onClose={handleDeleteCompaniesCancel}
          slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent sx={{ width: 900 }}>
          <h2 id="unstyled-modal-title" className="modal-title">
            About To Delete Access Keys
          </h2>
          <p id="unstyled-modal-description" className="modal-description">
            You are about to remove access to this channel for these companies: {companiesToRemove.join(', ')}.
            <p>All associated customer access keys will
              be deleted and the customers will no longer have access to this channel via the download API.</p>
          </p>
          <h3 id="unstyled-modal-confirm-delete" className="modal-description" style={{padding: '16px 0px', color: '#b30000'}}>
            <b>Are you really sure?</b>
          </h3>
          <div>
            <TriggerButton type="button" style={{width: 100, marginLeft: '0px', marginRight: '32px', backgroundColor: '#FF3333', border: '1px solid #FF3333'}} onClick={saveModifiedChannel}>Ok</TriggerButton>
            <TriggerButton type="button" style={{width: 100, backgroundColor: 'grey', border: '1px solid grey'}} onClick={handleDeleteCompaniesCancel}>Cancel</TriggerButton>
          </div>
        </ModalContent>
      </Modal>

  async function saveModifiedChannel() {
    let formData = new FormData();

    // user is allowed to submit without giving companies access
    if(targetCompanies.length >0)
    {
      const companyIdArray = targetCompanies.reduce((acc, obj) => {
        acc.push(obj.id);
        return acc;
      }, []);
      let companyPermissions = JSON.stringify(companyIdArray);
      console.log(companyPermissions);
      formData.append('permissions', companyPermissions);
      console.log("there are companies");
      console.log(companyPermissions);
    }
    else
    {
      // user has cleared any existing companies (or there were originally none)
      formData.append('permissions', "[]");
      console.log("clear companies");
    }

    formData.append('channelUniqueId', channelUniqueId);
    formData.append('name', channelName);
    formData.append('description', channelDescription);
    formData.append('navigationId', targetNode.toString());

    const requestOptions = {
      method: 'PATCH',
      body: formData
    };

    await fetch(apiUpdateChannel, requestOptions)
        .then(response => response.json())
        .catch((error) => console.error('Error when PATCHing data:', error));

    window.location.replace(Routing.getChannelManagementViewUrl());
  }

  function handleDeleteCompaniesCancel() {
      setCompanyDeletedModalOpen(false);
  }

  return (
    <div>
      <TriggerButton sx={{ marginRight: 2 }} type="button" onClick={handleOpen}>
        Modify
      </TriggerButton>
      <Modal
        aria-labelledby="modify-modal-title"
        aria-describedby="modify-modal-description"
        open={open}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent sx={{ width: 900, padding:'24px', overflow: 'auto', maxHeight: '90vh' }}>
          <h2 id="unstyled-modal-title" className="modal-title">Modifying {channelName}</h2>
          <p id="unstyled-modal-description" className="modal-description">{channelDescription}</p>
          <p id="unstyled-modal-description" className="modal-description"><b>Shared Path: </b>{channelSharedPath}</p>
          <NodePickerModal setTargetNode={setTargetNode} channelSharedPath={channelSharedPath}/>
          
          <p><b>Permissions</b></p>
          <CompanyPickerModal channelUniqueId={selectedChannelUniqueId} targetCompanies={targetCompanies} setTargetCompanies={setTargetCompanies} />
          {targetCompanies !== null && (
          <TableContainer sx={{ marginTop: '16px', minHeight: 138 }}>
			      <Table width="100%" className="channelCompaniesTable">
              <TableHead style={{background: 'powderblue'}}>
                <TableRow>
                  <TableCell width="150px"><b>Company</b></TableCell>
                  <TableCell width="50px"><b>Domain</b></TableCell>
                </TableRow>
              </TableHead>
				      <TableBody>
					      {targetCompanies.map((item, id) => (
                  <TableRow key={`channelCompaniesTable-item_${id}`}>
                    <TableCell width="150px">
                      {item.companyName}
                    </TableCell>
                    <TableCell width="50px">
                      {item.domain }                    
                    </TableCell>
                  </TableRow>
					      ))}
				      </TableBody>
			      </Table>
		      </TableContainer>
          )}

          <div style={{marginTop: '16px'}}>
            <TriggerButton type="button" onClick={handleModify}>
              Save
            </TriggerButton>
            <TriggerButton type="button" style={{backgroundColor: 'grey', border: '1px solid grey'}} onClick={handleCloseReset}>
              Cancel
            </TriggerButton>
          </div>
        </ModalContent>
      </Modal>
      {companyDeletedModal}
    </div>
  );
}

const Backdrop = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'base-Backdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')({
  fontFamily: 'IBM Plex Sans, sans-serif',
  fontWeight: 500,
  textAlign: 'start',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  overflow: 'hidden',
  backgroundColor: '#fff',
  borderRadius: '8px',
  border: '1px solid #ddd',
  boxShadow: '0 4px 12px rgb(0 0 0 / 0.2)',
  padding: '24px',
  color: '#000',

  '& .modal-title': {
    margin: 0,
    lineHeight: '1.5rem',
    marginBottom: '8px',
  },

  '& .modal-description': {
    margin: 0,
    lineHeight: '1.5rem',
    fontWeight: 400,
    color: '#666', // Static light description text color
    marginBottom: '4px',
  },
});

const TriggerButton = styled('button')({
  fontFamily: 'IBM Plex Sans, sans-serif',
  fontWeight: 600,
  width: 100,
  fontSize: '0.875rem',
  lineHeight: 1.5,
  padding: '8px 16px',
  marginRight: '16px',
  transition: 'all 150ms ease',
  cursor: 'pointer',
  background: '#3380FF',
  border: '1px solid #3380FF',
  color: 'white',

  '&:hover': {
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.35)',
  }
});