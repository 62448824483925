import GroupIcon from "@mui/icons-material/Group";
import { Box } from "@mui/system";
import * as React from "react";
import Texts from "../../../resources/Texts";
import "./FileAudienceSection.scss";
import SecurityPermissionsColumn from "./SecurityPermissionsColumn";
import CompanySelectColumn from "./CompanySelectColumn";
import TargetAudienceColumn from "./TargetAudienceColumn";
import { ApplicationState } from "../../../store";
import * as FileManagementStore from "../../../store/FileManagement";
import { connect } from "react-redux";
import { FilePermission } from "../../../resources/Enums";

type FileAudienceSectionProps = FileManagementStore.FileManagementState & {};

const FileAudienceSection: React.FunctionComponent<FileAudienceSectionProps> = (props) => {
	return (
		<Box
			className="fileManagement-formSection fileManagement-audienceSection"
			display="flex"
			flexDirection="column"
			flex={1}
		>
			<Box className="fileManagement-section-title" display="flex" alignItems="center">
				<GroupIcon className="icon" />
				{Texts.FileManagementView.Sections.Audience.Title}
			</Box>

			<Box className="fileManagement-audience-controls" display="flex" flex={1}>
				<SecurityPermissionsColumn />

				{props.fileData?.permission === FilePermission.Registered ? (
					<>
						<CompanySelectColumn />

						<TargetAudienceColumn />
					</>
				) : (
					<>
						<Box
							className="fileManagement-audience-column placeholder"
							display="flex"
							flexDirection="column"
							flex={[0, 2]}
						></Box>

						<Box
							className="fileManagement-audience-column placeholder"
							display="flex"
							flexDirection="column"
							flex={[0, 1]}
						></Box>
					</>
				)}
			</Box>
		</Box>
	);
};

export default connect((state: ApplicationState) => state.fileManagement)(FileAudienceSection);
