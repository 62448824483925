import * as React from 'react';
import clsx from 'clsx';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CompanyPicker from './CompanyPicker/CompanyPicker';
import { IChannelCompany } from '../../resources/Contracts';

type CompanyPickerProps = {
  channelUniqueId: string,
  targetCompanies: IChannelCompany[],
  setTargetCompanies,
}

//export default function CompanyPickerModal(selectedChannelUniqueId, { setTargetCompanies }) {
const CompanyPickerModal = ({channelUniqueId, targetCompanies, setTargetCompanies}) => {
  const [open, setOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
      if (selectedCompanies.length === 0) {
        setErrorOpen(true);
        return;
      }
      setTargetCompanies(selectedCompanies);
      setOpen(false);
  }
  const handleCancel = () => {
    setOpen(false);
  }
  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorOpen(false);
  };

  const [selectedCompanies, setSelectedCompanies] = React.useState<IChannelCompany[]>(targetCompanies);

  return (
    <div>
      <TriggerButton type="button" onClick={handleOpen}>
        Select companies
      </TriggerButton>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent sx={{ height: 700, width: 900 }}>
          <Snackbar open={errorOpen}
                    autoHideDuration={3000}
                    onClose={handleErrorClose}
                    sx={{zIndex: 9999}}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
            <Alert
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
            >
              Please select at least one company
            </Alert>
          </Snackbar>
          <h2 id="unstyled-modal-title" className="modal-title">
            Permissions         
          </h2>
          <p id="unstyled-modal-description" className="modal-description">
            Manage company permissions for this channel.  All users of the selected companies will be able to see the channel but must generate their own Access Key.
          </p>
          <CompanyPicker channelUniqueId={channelUniqueId} targetCompanies={targetCompanies} setTargetCompanies={setSelectedCompanies} />
          <div>
            <TriggerButton type="button"  style={{width : '100px'}} onClick={handleClose}>OK</TriggerButton>
            <TriggerButton style={{width : '100px', backgroundColor: 'grey',   border: '1px solid grey', marginLeft: '16px', marginTop: '16px'}} type="button" onClick={handleCancel}>Cancel</TriggerButton>
          </div>
        </ModalContent>
       
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'base-Backdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `,
);

const TriggerButton = styled('button')({
  fontFamily: 'IBM Plex Sans, sans-serif',
  fontWeight: 600,
  fontSize: '0.875rem',
  lineHeight: 1.5,
  padding: '8px 16px',
  transition: 'all 150ms ease',
  cursor: 'pointer',
  background: '#3380FF',
  border: '1px solid #3380FF',
  color: 'white',

  '&:hover': {
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.35)',
  }
});

export default CompanyPickerModal