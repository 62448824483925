/*
  NodePickerModal component (tsx)
  --------------------------------------------------------------------------------
  - Wraps NodePicker.tsx into a Material UI Modal dialogue
  - Parent is AddChannelModal.tsx
  - Updates state (targetNode) of parent
*/
import * as React from 'react';
import clsx from 'clsx';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import NodePicker from './NodePicker/NodePicker';

export default function NodePickerModal({ setTargetNode, channelSharedPath="" }) {
  const [open, setOpen] = React.useState(false);
  const [selectedNode, setSelectedNode] = React.useState({node: null, isLeafNode: false});
  const [errorOpen, setErrorOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (channelSharedPath === "" && !selectedNode.isLeafNode) {
      setErrorOpen(true);
      return;
    }
    if (channelSharedPath !== "" && selectedNode.node && !selectedNode.isLeafNode) {
      setErrorOpen(true);
      return;
    }
    if (selectedNode.node) setTargetNode(selectedNode.node);
    setSelectedNode({node: null, isLeafNode: false});
    setOpen(false);
  }
  const handleCloseReset = () => {
    setSelectedNode({node: null, isLeafNode: false});
    setOpen(false);
  }
  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorOpen(false);
  };
  const handleNodeSelected = (node, isLeafNode) => {
    setSelectedNode({node, isLeafNode});
  }

  return (
    <div>
      <TriggerButton type="button" onClick={handleOpen}>
        Select path
      </TriggerButton>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent sx={{ height: 700, width: 900 }}>
          <Snackbar open={errorOpen}
                    autoHideDuration={3000}
                    onClose={handleErrorClose}
                    sx={{zIndex: 9999}}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
            <Alert
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
            >
              Please select a leaf category in the tree
            </Alert>
          </Snackbar>
          <h2 id="unstyled-modal-title" className="modal-title">
            Share Path Selection         
          </h2>
          { channelSharedPath !== "" && <p>Current Shared Path: {channelSharedPath}</p>
          }
          <p id="unstyled-modal-description" className="modal-description">
            Select the leaf category to share with this channel then press OK.  All files beneath the category you select will be available to users, subject to portal permissions.
          </p>
          <div>
            { selectedNode.node && <TriggerButton type="button" style={{width: '100px', marginBottom: '16px', marginRight: '16px'}} onClick={handleClose}>OK</TriggerButton>}
            <TriggerButton type="button" style={{width: '100px',backgroundColor: 'grey', border: '1px solid grey'}} onClick={handleCloseReset}>Cancel</TriggerButton>
          </div>
          <NodePicker setTargetNode={handleNodeSelected}/>
          </ModalContent>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'base-Backdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')({
  fontFamily: 'IBM Plex Sans, sans-serif',
  fontWeight: 500,
  textAlign: 'start',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  overflow: 'hidden',
  backgroundColor: '#fff',
  borderRadius: '8px',
  border: '1px solid #ddd',
  boxShadow: '0 4px 12px rgb(0 0 0 / 0.2)',
  padding: '24px',
  color: '#000',

  '& .modal-title': {
    margin: 0,
    lineHeight: '1.5rem',
    marginBottom: '8px',
  },

  '& .modal-description': {
    margin: 0,
    lineHeight: '1.5rem',
    fontWeight: 400,
    color: '#666', // Static light description text color
    marginBottom: '4px',
  },
});

const TriggerButton = styled('button')({
  fontFamily: 'IBM Plex Sans, sans-serif',
  fontWeight: 600,
  fontSize: '0.875rem',
  lineHeight: 1.5,
  padding: '8px 16px',
  transition: 'all 150ms ease',
  cursor: 'pointer',
  background: '#3380FF',
  border: '1px solid #3380FF',
  color: 'white',

  '&:hover': {
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.35)',
  }
});