import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { CurrentUserState } from "../../../store/CurrentUser";

type RoleRestrictedComponentProps = CurrentUserState & {
	enabledRoles: string[];
};

const RoleRestrictedComponent: React.FC<React.PropsWithChildren<RoleRestrictedComponentProps>> = (
	props
) =>
	{
		if (
			props.user?.hasRoles?.some((userRole) =>
				props.enabledRoles.some((enabledRole) => enabledRole === userRole)
			) || props.user?.hasUploadPermission
		)
	{
		return <>{props.children}</>;
	}

	return null;
};

export default connect((state: ApplicationState) => state.currentUser)(RoleRestrictedComponent);
