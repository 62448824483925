import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import { SolutionsGroup } from "../../resources/Enums";
import { ApplicationState } from "../../store";
import { CurrentUserState } from "../../store/CurrentUser";
import SolutionsBrowserDetail from "./SolutionBrowserDetail";
import "./SolutionsBrowser.scss";
import SolutionsBrowserTree from "./SolutionsBrowserTree";
import SolutionsTabs from "./SolutionsTabs";
import Texts from "../../resources/Texts";
import { parseViewParameters } from "../../utils/viewUtils";
import { SolutionsViewRouteParamaters } from "../../resources/RouteParams";
import { useLocation } from "react-router";

type SolutionsBrowserProps = CurrentUserState & {
	solutionGroup: string;
	solutionId: number;
	showTabs: boolean;
	defaultSelectedId?: string;
};

const SolutionsBrowser: React.FC<SolutionsBrowserProps> = ({
	solutionGroup,
	solutionId,
	showTabs,
	defaultSelectedId,
	user,
}) => {
	const viewParameters = parseViewParameters<SolutionsViewRouteParamaters>(useLocation().search);

	let browserAccessible: boolean = true;

	if (
		!user &&
		solutionGroup !== SolutionsGroup.EOL &&
		solutionGroup !== SolutionsGroup.FieldNotices 
	) {
		browserAccessible = false;
	}

	if (!browserAccessible) {
		return (
			<Box className="solutionsBrowser">
				<Typography className="unauthorizedMessage" variant="inherit">{Texts.SolutionsView.UnauthorizedAccess}</Typography>
			</Box>
		);
	}

	return (
		<Box className="solutionsBrowser">
			{showTabs && (
				<SolutionsTabs
					solutionGroup={solutionGroup}
					solutionId={solutionId}
					defaultSelectedTabId={viewParameters.selectedTabId}
				/>
			)}

			<Box display="flex" flexDirection="row" className="solutionsBrowser-wrapper" flex={1}>
				<Box display="flex" className="solutionsBrowser-tree-wrapper" flex={3}>
					<SolutionsBrowserTree
						solutionId={solutionId}
						solutionGroup={solutionGroup}
						defaultSelectedId={defaultSelectedId}
					/>
				</Box>

				<Box display="flex" className="solutionsBrowser-detail-wrapper" flex={4}>
					<SolutionsBrowserDetail />
				</Box>
			</Box>
		</Box>
	);
};

export default connect((state: ApplicationState) => state.currentUser)(SolutionsBrowser);
