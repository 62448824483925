import { Collapse } from "@mui/material";
import * as React from "react";
import AddCategoryInput from "../shared/AddCategoryInput";

type NewSolutionsTabsInputProps = {
	visible: boolean;
	onAdd: (name: string) => void;
	onCancel: () => void;
};

const NewSolutionsTabsInput: React.FC<NewSolutionsTabsInputProps> = (props) => {
	const handleAddClick = (name: string): void => {
		props.onAdd(name);
	};

	const handleCancelClick = (): void => {
		props.onCancel();
	};

	return (
		<Collapse in={props.visible} className="newSolutionsTab-input-wrapper" unmountOnExit>
			<AddCategoryInput onAdd={handleAddClick} onCancel={handleCancelClick} />
		</Collapse>
	);
};

export default NewSolutionsTabsInput;
