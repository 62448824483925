import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { Link } from "react-router-dom";
import { Routing } from "../../resources/Routes";
import Texts from "../../resources/Texts";
import "./ViewHeader.scss";

interface ViewHeaderProps {
	className?: string;
	title: string;
}

const ViewHeader: React.FunctionComponent<ViewHeaderProps> = (props) => {
	return (
		<Box
			className={props.className ? `viewHeader ${props.className}` : "viewHeader"}
			display="flex"
			flexDirection="row"
			position="relative"
		>
			<Container>
				<Box
					className="viewHeader-nav"
					display="flex"
					flexDirection="row"
					alignItems="center"
				>
					<Box
						className="viewHeader-nav-button"
						display="flex"
						flexDirection="row"
						alignItems="center"
					>
						<Link className="backLink link" to={Routing.getHomeViewUrl()}>
							<ArrowBackIosNewOutlinedIcon />
							{Texts.Navigation.Back}
						</Link>
					</Box>
					<Typography className="viewHeader-caption" variant="inherit">
						{props.title}
					</Typography>
				</Box>
			</Container>
		</Box>
	);
};

export default ViewHeader;
