import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import Save from "@mui/icons-material/Save";
import Cancel from "@mui/icons-material/Cancel";
import { Button, IconButton, TableCell, TableRow } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import * as Contracts from "../../resources/Contracts";
import TextFormField from "../shared/formControls/TextFormField";

type CategoryTableProps = {
	category: Contracts.ICategory;
	onDelete: (id: number) => void;
	onSave: (value: Contracts.ICategory) => void;
};

const CategoryTableRow: React.FunctionComponent<CategoryTableProps> = ({
	category,
	onDelete,
	onSave,
}) => {
	const [value, setValue] = React.useState<Contracts.ICategory>(category);
	const [editModeActive, setEditMode] = React.useState<boolean>(false);

	React.useEffect(() => {
		setValue(category);
	}, [category]);

	let icon: string;

	if (value.icon) {
		if (value.icon.startsWith("data:")) {
			const splitIconValue = value.icon.split(",");

			icon = splitIconValue[splitIconValue.length - 1];
		} else {
			icon = value.icon;
		}
	}

	const handleNameChange = (nameValue: string) => {
		setValue({
			...value,
			navigationName: nameValue,
		});
	};

	const handleIconChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const selectedFile: File = event.target.files?.[0];

		if (selectedFile) {
			var reader = new FileReader();

			reader.readAsDataURL(selectedFile);

			reader.onload = function () {
				setValue({ ...value, icon: reader.result as string });
			};

			reader.onerror = function (error) {
				console.log("Error: ", error);
			};
		}
	};

	const handleDelete = () => {
		onDelete(value.id);
	};

	const handleCancel = () => {
		setEditMode(false);
		setValue(category);
	};

	const handleEdit = () => {
		if (editModeActive) {
			onSave(value);
			setEditMode(false);
		} else {
			setEditMode(true);
		}
	};

	return (
		<TableRow>
			<TableCell width="300px">
				{editModeActive ? (
					<TextFormField value={value.navigationName || ""} onChange={handleNameChange} />
				) : (
					value.navigationName || <>&nbsp;</>
				)}
			</TableCell>
			<TableCell width="216px">
				{editModeActive ? (
					<Button component="label">
						<img
							src={`data:image/svg+xml;data:image/png;base64, ${icon}`}
							style={{ cursor: "pointer" }}
						/>
						<input type="file" hidden onChange={handleIconChange} accept="image/*" />
					</Button>
				) : value.icon ? (
					<img src={`data:image/svg+xml;data:image/png;base64, ${icon}`} />
				) : (
					<Box height="40px"></Box>
				)}
			</TableCell>
			<TableCell width="100px">
				<IconButton component="span" onClick={handleEdit}>
					{editModeActive ? <Save /> : <Edit />}
				</IconButton>

				{editModeActive && (
					<IconButton component="span" onClick={handleCancel}>
						<Cancel />
					</IconButton>
				)}

				<IconButton component="span" onClick={handleDelete}>
					<Delete />
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default CategoryTableRow;
