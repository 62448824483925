import * as React from "react";
import ChannelManagementContainer from "../../components/channelManagement/ChannelManagementContainer";
import { Box } from "@mui/system";
import { Container } from "@mui/material";
import "./ChannelManagementView.scss";

interface ChannelManagementProps {}

const ChannelManagementView: React.FunctionComponent<ChannelManagementProps> = (props) => {

	return (
		<Box className="channelmanagement-view" display="flex" flexDirection="column">
			<Container>
				<ChannelManagementContainer />
			</Container>
		</Box>
	);
};

export default ChannelManagementView;
