import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import { ISolutionTreeItem } from "../../resources/Contracts";
import { Service } from "../../services/Service";
import { ApplicationState } from "../../store";
import * as SelectedSolutionStore from "../../store/SelectedSolution";
import { actionCreators as AlertStoreActionCreators } from "../../store/AlertStore";
import BrowserDetailTable from "./BrowserDetailTable";
import SolutionsBrowserDetailTabs from "./SolutionsBrowserDetailTabs";
import { bindActionCreators } from "redux";
import { handleErrorMessage } from "../../utils/utils";

type SolutionsBrowserDetailProps = SelectedSolutionStore.SelectedSolutionState &
	typeof AlertStoreActionCreators &
	typeof SelectedSolutionStore.actionCreators & {};

const SolutionsBrowserDetail: React.FC<SolutionsBrowserDetailProps> = (props) => {
	const [tabValue, setTabValue] = React.useState<string>(null);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [detailItems, setDetailItems] = React.useState<ISolutionTreeItem[]>([]);

	const loadFolderDetail = React.useCallback(async (): Promise<void> => {
		setLoading(true);

		try {
			const responseDetailItem: ISolutionTreeItem = await Service.getDetailItem(
				props.selectedSolution.id
			);

			setDetailItems(responseDetailItem.nodes);

			if (responseDetailItem.nodes?.length > 0) {
				setTabValue(responseDetailItem.nodes[0].id);
			}
		} catch (error) {
			// TODO LOG IN TO ERROR COMPONENT
			console.error(error);

			setTabValue(null);
		}

		setLoading(false);
	}, [props.selectedSolution]);

	React.useEffect(() => {
		if (props.selectedSolution) {
			loadFolderDetail();
		} else {
			setDetailItems([]);
			setTabValue(null);
		}
	}, [props.selectedSolution, loadFolderDetail]);

	const handleDelete = async (id: string): Promise<void> => {
		try {
			await Service.deleteFile(id);

			const detailItemsCopy: ISolutionTreeItem[] = detailItems.slice();

			detailItemsCopy.forEach((tab) => {
				tab.nodes?.forEach((di) => {
					const index: number = di.nodes.findIndex((x) => x.id === id);

					if (index > -1) {
						di.nodes.splice(index, 1);
					}
				});
			});

			setDetailItems(detailItemsCopy);
		} catch (error) {
			props.addErrorAlert(handleErrorMessage(error));
		}
	};

	const handleChangeTab = (folderId: string) => {
		setTabValue(folderId);
	};

	return props.selectedSolution ? (
		loading ? (
			<Box display="flex" flex={1} justifyContent="center" alignItems="center">
				<CircularProgress />
			</Box>
		) : (
			<Box className="solutionBrowser-detail" display="flex" flexDirection="column" flex={1}>
				<SolutionsBrowserDetailTabs
					tabItems={detailItems}
					value={tabValue}
					onChange={handleChangeTab}
				/>

				<BrowserDetailTable
					detailNodes={
						tabValue && detailItems?.find((node) => node.id === tabValue)?.nodes
					}
					onDelete={handleDelete}
				/>
			</Box>
		)
	) : null;
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...SelectedSolutionStore.actionCreators,
			...AlertStoreActionCreators,
		},
		dispatch
	);
};

export default connect(
	(state: ApplicationState) => state.selectedSolutions,
	mapDispatchToProps
)(SolutionsBrowserDetail);
