import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import * as React from "react";
import Texts from "../../resources/Texts";
import "./ConfirmPublicPermissionDialog.scss";

type ConfirmPublicPermissionDialogProps = {
	open: boolean;
	close: () => void;
	onConfirm: () => void;
};

const ConfirmPublicPermissionDialog: React.FunctionComponent<ConfirmPublicPermissionDialogProps> = (
	props
) => {
	const handleConfirm = (): void => {
		props.onConfirm();
		props.close();
	};

	return (
		<Dialog
			maxWidth="lg"
			open={props.open}
			onClose={props.close}
			onClick={(e) => e.stopPropagation()}
			className="confirmPublic-dialog"
		>
			<DialogContent>
				<DialogContentText>
					<Box display="flex" flexDirection="column">
						<span>{Texts.Dialogs.PublicPermissionConfirmation.ContextText}</span>

						<span>{Texts.Dialogs.PublicPermissionConfirmation.ConfirmText}</span>
					</Box>
				</DialogContentText>
			</DialogContent>

			<DialogActions>
				<Box display="flex" flex={1} justifyContent="space-between">
					<Button className="button-primary" variant="contained" onClick={handleConfirm}>
						{Texts.Buttons.ConfirmPublic}
					</Button>

					<Button className="button-primary" variant="contained" onClick={props.close}>
						{Texts.Buttons.RevertToPublic}
					</Button>
				</Box>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmPublicPermissionDialog;
