import { InputLabel } from "@mui/material";
import * as React from "react";
import "./FormField.scss";
import "./NumberFormField.scss";

interface NumberFormFieldProps {
	className?: string;
	label?: string;
	value: number;
	onChange: (value: number) => void;
	disabled?: boolean;
}

const NumberFormField: React.FC<NumberFormFieldProps> = (props) => {
	const handleOnChange = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	): void => {
		const replacedValue: string = event.target.value?.replace(/[^0-9]/g, "");

		if (replacedValue) {
			const parsedValue: number = parseInt(replacedValue);

			if (!isNaN(parsedValue) && parsedValue >= 0) {
				props.onChange(parsedValue);
			}
		} else {
			props.onChange(null);
		}
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
		const isKeyNumber: boolean = /^[0-9]$/i.test(event.key);

		if (!isKeyNumber) {
			event.preventDefault();
		}
	};

	const padLeadingZeros = (num, size) => {
		var s = num + "";
		while (s.length < size) s = "0" + s;
		return s;
	}

	const className: string = props.className
		? `formField formField-number ${props.className}`
		: "formField formField-number";

	return (
		<>
			{props.label && <InputLabel className="formField-label">{props.label}</InputLabel>}

			<input
				type="number"
				className={className}
				disabled={props.disabled}
				value={props.value === 0 ? 0 : padLeadingZeros(props.value, 2) || ""}
				step="1"
				maxLength={2}
				onKeyPress={handleKeyPress}
				onChange={handleOnChange}
			/>
		</>
	);
};

export default NumberFormField;
