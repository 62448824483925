import { Action, Reducer } from "redux";
import { IUser } from "../resources/Contracts";

export interface CurrentUserState {
	user: IUser;
}

export interface SetCurrentUserNameAction {
	type: "SET_CURRENT_USER";
	payload: IUser;
}

export type KnownAction = SetCurrentUserNameAction;

export const actionCreators = {
	setCurrentUserName: (user: IUser) =>
		({ type: "SET_CURRENT_USER", payload: user } as SetCurrentUserNameAction),
};

export const reducer: Reducer<CurrentUserState> = (
	state: CurrentUserState | undefined,
	incomingAction: Action
) => {
	if (state === undefined) {
		return { user: null };
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case "SET_CURRENT_USER":
			return { user: action.payload };
		default:
			return state;
	}
};
