import { Box, Button, TextField } from "@mui/material";
import * as React from "react";
import Texts from "../../resources/Texts";
import "./AddCategoryInput.scss";

type AddCategoryInputProps = {
	onAdd: (name: string) => void;
	onCancel: () => void;
	defaultValue?: string;
	primaryButtonText?: string;
};

const AddCategoryInput: React.FC<AddCategoryInputProps> = (props) => {
	const [inputValue, setInputValue] = React.useState<string>(props.defaultValue || "");

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setInputValue(event.target.value);
	};

	const handleAddClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		event.stopPropagation();

		props.onAdd(inputValue);

		setInputValue("");
	};

	const handleCancelClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		event.stopPropagation();

		props.onCancel();

		setInputValue(props.defaultValue || "");
	};

	return (
		<Box display="flex" alignItems="center" flex={1} className="addCategory-input-wrapper">
			<TextField
				className="addCategory-input"
				value={inputValue}
				onChange={handleInputChange}
				onClick={(e) => e.stopPropagation()}
			/>

			<Button
				className="button button-add"
				variant="contained"
				onClick={handleAddClick}
				disabled={!inputValue || inputValue === ""}
			>
				{props.primaryButtonText || Texts.SolutionsView.Tabs.AddButton}
			</Button>

			<Button
				className="button button-cancel"
				variant="contained"
				onClick={handleCancelClick}
			>
				{Texts.SolutionsView.Tabs.CancelButton}
			</Button>
		</Box>
	);
};

export default AddCategoryInput;
