import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import Texts from "../../../resources/Texts";
import { ApplicationState } from "../../../store";
import * as FileManagementStore from "../../../store/FileManagement";
import "./FileInfoColumn.scss";
import { format } from "date-fns";
import { formatFileSize } from "../../../utils/utils";

type FileInfoColumnProps = FileManagementStore.FileManagementState & {};

const FileInfoColumn: React.FunctionComponent<FileInfoColumnProps> = (props) => {
	return (
		<Box
			className="fileManagement-metadata-column info-column"
			display="flex"
			flexDirection="column"
			flex={[0, 1]}
		>
			<Box className="column-title" display="flex" flex={[1, 0]}>
				{Texts.FileManagementView.Sections.Metadata.InfoColumn.Title}
			</Box>

			<Box className="infoFields-wrapper" display="flex" flexDirection="column">
				<Box className="infoField">
					<span className="infoField-label">
						{
							Texts.FileManagementView.Sections.Metadata.InfoColumn.FieldTitles
								.MdChecksum
						}
						:
					</span>

					<span className="infoField-value">
						{props.fileData?.metadata?.downloadInfo?.mD5Checksum}
					</span>
				</Box>

				<Box className="infoField">
					<span className="infoField-label">
						{Texts.FileManagementView.Sections.Metadata.InfoColumn.FieldTitles.Size}:
					</span>

					<span className="infoField-value">
						{props.fileData?.metadata?.downloadInfo?.size &&
							formatFileSize(props.fileData.metadata.downloadInfo.size)}
					</span>
				</Box>

				<Box className="infoField">
					<span className="infoField-label">
						{
							Texts.FileManagementView.Sections.Metadata.InfoColumn.FieldTitles
								.UploadedBy
						}
						:
					</span>

					<span className="infoField-value">
						{props.fileData?.metadata?.downloadInfo?.uploadedBy}
					</span>
				</Box>

				<Box className="infoField">
					<span className="infoField-label">
						{
							Texts.FileManagementView.Sections.Metadata.InfoColumn.FieldTitles
								.TimeStamp
						}
						:
					</span>

					<span className="infoField-value">
						{props.fileData?.metadata?.downloadInfo?.timeStamp &&
							format(
								new Date(props.fileData.metadata.downloadInfo.timeStamp),
								"dd/MM/yyyy hh:mm:ss a"
							)}
					</span>
				</Box>
			</Box>
		</Box>
	);
};

export default connect((state: ApplicationState) => state.fileManagement)(FileInfoColumn);
