import { Box } from "@mui/system";
import * as React from "react";
import NavMenu from "./navMenu/NavMenu";
import { LayoutFooter } from "./LayoutFooter";
import AlertOverlay from "./AlertOverlay";

export default (props: { children?: React.ReactNode }) => (
	<>
		<NavMenu />

		<Box display="flex" flexDirection="column" flex={1}>
			{props.children}
		</Box>

		<LayoutFooter />

		<AlertOverlay />
	</>
);
