import * as React from 'react';
import clsx from 'clsx';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CustomTextField from '../../channelManagement/CustomTextField';
import { IChannel, IChannelCompany, IChannelPermission, IAccessKey } from '../../../resources/Contracts';
import { Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Routing } from '../../../resources/Routes';

export default function GetAccessKeyModal({selectedChannelUniqueId, myCompanyId}) {
  const [open, setOpen] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [idToDelete, setIdToDelete] = React.useState(null);

  const maxNumAccessKeys = 5;

  // UI element state
  const [channelName, setChannelName] = React.useState("");
  const [channelDescription, setChannelDescription] = React.useState("");
  const [channelIsEnabled, setChannelIsEnabled] = React.useState("");
  const [channelIsBlocked, setChannelIsBlocked] = React.useState("");
  const [channelUniqueId, setChannelUniqueId] = React.useState("");
  const [channelSharedPath, setChannelSharedPath] = React.useState("");
  const [channelNavigationId, setChannelNavigationId] = React.useState(0);
  const [isHovered, setIsHovered] = React.useState(false);

  // Data state
  const [channelAccessKeys, setChannelAccessKeys] = React.useState<IAccessKey[]>([]);
  // const [channels, setChannels] = React.useState<IChannel[]>([]);
  
  
  // API urls
  const apiGetChannel = `/api/Channel/${selectedChannelUniqueId}`;
  const apiGetAccessKeys =  `/api/Channel/myaccesskeys/${selectedChannelUniqueId}`;
  const apiNewAccessKey = '/api/Channel/myaccesskeys';
  const apiDeleteAccessKey = `/api/Channel/myaccesskeys/${selectedChannelUniqueId}`
  const apiResolveCompanyNames = '/api/Channel/augment/companies';

  const deleteButton = {
    backgroundColor: '#FF3333', color: 'white', border: '1px solid #FF3333', padding: '8px',
    cursor: 'pointer',
    boxShadow: isHovered ? '0px 4px 6px rgba(0, 0, 0, 0.35)' : 'none',
    transition: 'box-shadow 0.3s ease'
  };

  async function fetchChannel() {
    const response = await fetch(apiGetChannel);
    if(response.ok)
    {
      const channel = await response.json();
      return channel[0];
    }
    return [];
  }

  async function fetchChannelAccessKeys() {
    const response = await fetch(apiGetAccessKeys);
    if(response.ok) return await response.json();
    return [];
  }

  async function reloadChannelAccessKeys() {
    const getAccessKeys = await fetchChannelAccessKeys();
    if(getAccessKeys !== null) setChannelAccessKeys(getAccessKeys);
  }

  const handleOpen = async () => {
    const getChannel = await fetchChannel();
    const getAccessKeys = await fetchChannelAccessKeys();
    if(getAccessKeys !== null) setChannelAccessKeys(getAccessKeys);    
    if(getChannel !== null) {
      setChannelName(getChannel.name);
      setChannelDescription(getChannel.description);
      setChannelUniqueId(getChannel.channelUniqueId);
      setChannelSharedPath(getChannel.resolvedNavigationNode);
      setChannelNavigationId(getChannel.channelNavigationId);
    }
    setOpen(true);
  }
  
  const handleClose = () => setOpen(false);
  const handleCloseReset = () => setOpen(false);
  
  async function handleRequestKey(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    let formData = new FormData();
    formData.append('CompanyId', myCompanyId);
    formData.append('ChannelUniqueId', selectedChannelUniqueId)

    const requestOptions = {
      method: 'POST',
      body: formData
    };

    await fetch(apiNewAccessKey, requestOptions)
      .catch((error) => console.error('Error when POSTing data:', error));
      reloadChannelAccessKeys();
  }


  async function handleDeleteKey(Id: number): Promise<React.MouseEventHandler<HTMLButtonElement>> {
    setDeleteModalOpen(true);
    setIdToDelete(Id);
    return;
  }

  async function handleDeleteConfirm(): Promise<React.MouseEventHandler<HTMLButtonElement>> {
    setDeleteModalOpen(false);
    let deleteUrl = `${apiDeleteAccessKey}/${idToDelete}`;
    const requestOptions = {
      method: 'DELETE'
    };
    await fetch(deleteUrl, requestOptions)
      .catch((error) => console.error('Error when DELETING data:', error));
    reloadChannelAccessKeys();
    setIdToDelete(null);
    return;
  }

  const handleCopy = (event) => {
    const tableCell = event.currentTarget.closest('td');
    const textToCopy = tableCell.textContent.trim();
    navigator.clipboard.writeText(textToCopy);
  };

  const handleDeleteClose = () => {
    setIdToDelete(null);
    setDeleteModalOpen(false);
  }

  const deleteModal =
      <Modal
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={deleteModalOpen}
          onClose={handleClose}
          slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent sx={{ width: 900 }}>
          <h2 id="unstyled-modal-title" className="modal-title">
            Delete Access Key
          </h2>
          <p id="unstyled-modal-description" className="modal-description">
            You are about to delete this access key. After deletion you will no longer be able to access the download channel using this key.
          </p>
          <h3 id="unstyled-modal-confirm-delete" className="modal-description" style={{padding: '16px 0px', color: '#b30000'}}>
            <b>Are you really sure?</b>
          </h3>
          <div>
            <TriggerButton type="button" style={{width: 100, marginLeft: '0px', marginRight: '32px', backgroundColor: '#FF3333', border: '1px solid #FF3333'}} onClick={handleDeleteConfirm}>Ok</TriggerButton>
            <TriggerButton type="button" style={{width: 100, backgroundColor: 'grey', border: '1px solid grey'}} onClick={handleDeleteClose}>Cancel</TriggerButton>
          </div>
        </ModalContent>
      </Modal>

  return (
    <div>
      <TriggerButton type="button" sx={{marginLeft: '8px'}} onClick={handleOpen}>
        Manage Channel Access Keys
      </TriggerButton>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent sx={{ width: 1200, overflow: 'auto', maxHeight: '90vh' }}>
          <h2 id="unstyled-modal-title" className="modal-title">{channelName}</h2>
          <p id="unstyled-modal-description" className="modal-description">{channelDescription}</p>
            <Typography>
            <b>Shared Path: </b>{channelSharedPath}
            </Typography>
          <p><b>Access Keys</b></p>
        {channelAccessKeys !== null && (
        <TableContainer sx={{minHeight: 100}}>
			<Table width="100%" className="channelAccessKeysTable">
                <TableHead sx={{backgroundColor: 'powderblue'}}>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell width='230px'><b>Download Channel ID</b></TableCell>
                        <TableCell><b>Access Key</b></TableCell>
                        <TableCell width='350px'><b>Expires</b></TableCell>
                    </TableRow>
                </TableHead>
				<TableBody>
				{channelAccessKeys.map((item, Id) => (
                    <TableRow key={`channelAccessKeysTable-item_${Id}`}>
                        <TableCell>
                        
                        <button style={deleteButton}
                                onClick={() => handleDeleteKey(item.id)}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                        >Delete</button>
                          </TableCell>
                        <TableCell>
                            {item.channelUniqueId}
                          <IconButton component="span" onClick={handleCopy}>
                            <ContentCopyIcon sx={{fontSize: '16px'}}/>
                          </IconButton>
                        </TableCell>
                        <TableCell><span style={{ wordWrap: 'break-word', width: '600px', display: 'block' }}>
                            {item.accessKey}
                            <IconButton component="span" onClick={handleCopy}>
                              <ContentCopyIcon sx={{fontSize: '16px'}}/>
                            </IconButton>
                          </span>
                        </TableCell>
                        <TableCell>
                            {(new Date(item.expirationDate)).toUTCString()}
                        </TableCell>
                    </TableRow>
				))}
				</TableBody>
		    </Table>
		</TableContainer>
        )}


          <div style={{marginLeft: '32px', marginTop: '16px'}}>
          <TriggerButton type="button" onClick={handleCloseReset} sx={{width: 100, background: 'grey', border: '1px solid grey'}} >
            Cancel
          </TriggerButton>
            { channelAccessKeys.length < maxNumAccessKeys && <TriggerButton onClick={handleRequestKey} type="button" sx={{width: '280px', marginLeft: '16px'}}>Request A New Channel Access Key</TriggerButton>}
          </div>
        </ModalContent>
      </Modal>
      {deleteModal}
    </div>
  );
}

const Backdrop = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'base-Backdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')({
  fontFamily: 'IBM Plex Sans, sans-serif',
  fontWeight: 500,
  textAlign: 'start',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  overflow: 'hidden',
  backgroundColor: '#fff',
  borderRadius: '8px',
  border: '1px solid #ddd',
  boxShadow: '0 4px 12px rgb(0 0 0 / 0.2)',
  padding: '24px',
  color: '#000',

  '& .modal-title': {
    margin: 0,
    lineHeight: '1.5rem',
    marginBottom: '8px',
  },

  '& .modal-description': {
    margin: 0,
    lineHeight: '1.5rem',
    fontWeight: 400,
    color: '#666', // Static light description text color
    marginBottom: '4px',
  },
});

  const TriggerButton = styled('button')({
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontWeight: 600,
    marginLeft: '-16px',
    fontSize: '0.875rem',
    lineHeight: 1.5,
    padding: '8px 16px',
    transition: 'all 150ms ease',
    cursor: 'pointer',
    background: '#3380FF',
    border: '1px solid #3380FF',
    color: 'white',

    '&:hover': {
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.35)',
    }
  });
