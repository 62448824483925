import * as React from "react";
import { useLocation } from "react-router-dom";
import { FileManagementViewRouteParameters } from "../../resources/RouteParams";
import { parseViewParameters } from "../../utils/viewUtils";
import FileManagementContainer from "../../components/fileManagement/FileManagementContainer";
import { Box } from "@mui/system";
import { Container } from "@mui/material";
import "./FileManagementView.scss";

interface FileManagementViewProps {}

const FileManagementView: React.FunctionComponent<FileManagementViewProps> = (props) => {
	const viewParameters = parseViewParameters<FileManagementViewRouteParameters>(
		useLocation().search
	);

	return (
		<Box className="fileManagement-view" display="flex" flexDirection="column">
			<Container>
				<FileManagementContainer {...viewParameters} />
			</Container>
		</Box>
	);
};

export default FileManagementView;
