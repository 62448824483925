import { Box } from "@mui/system";
import * as React from "react";
import Texts from "../../../resources/Texts";
import * as FileManagementStore from "../../../store/FileManagement";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./VersionColumn.scss";
import NumberFormField from "../../shared/formControls/NumberFormField";

type VersionColumnProps = FileManagementStore.FileManagementState &
	typeof FileManagementStore.actionCreators & {};

const VersionColumn: React.FunctionComponent<VersionColumnProps> = (props) => {
	const handleMajorVerionChange = (value: number) => {
		props.setFileManagementFile({
			...props.fileData,
			metadata: {
				...props.fileData?.metadata,
				version: {
					...props.fileData?.metadata?.version,
					major: value,
				},
			},
		});
	};

	const handleMinorVerionChange = (value: number) => {
		props.setFileManagementFile({
			...props.fileData,
			metadata: {
				...props.fileData?.metadata,
				version: {
					...props.fileData?.metadata?.version,
					minor: value,
				},
			},
		});
	};

	const handlePatchVerionChange = (value: number) => {
		props.setFileManagementFile({
			...props.fileData,
			metadata: {
				...props.fileData?.metadata,
				version: {
					...props.fileData?.metadata?.version,
					patch: value,
				},
			},
		});
	};

	return (
		<Box
			className="fileManagement-metadata-column version-column"
			display="flex"
			flexDirection="column"
			flex={[0, 1]}
		>
			<Box className="column-title" display="flex" flex={[1, 0]}>
				{Texts.FileManagementView.Sections.Metadata.VersionColumn.Title}
			</Box>

			<Box className="version-input-wrapper" display="flex" flex={[1, 0]} alignItems="center">
				<Box className="version-input-label" display="flex">
					{Texts.FileManagementView.Sections.Metadata.VersionColumn.MajorFiledLable}
				</Box>

				<NumberFormField
					className="version-column-input"
					value={props.fileData?.metadata?.version?.major}
					onChange={handleMajorVerionChange}
				/>
			</Box>

			<Box className="version-input-wrapper" display="flex" flex={[1, 0]} alignItems="center">
				<Box className="version-input-label" display="flex">
					{Texts.FileManagementView.Sections.Metadata.VersionColumn.MinorFieldLabel}
				</Box>

				<NumberFormField
					className="version-column-input"
					value={props.fileData?.metadata?.version?.minor}
					onChange={handleMinorVerionChange}
				/>
			</Box>

			<Box className="version-input-wrapper" display="flex" flex={[1, 0]} alignItems="center">
				<Box className="version-input-label" display="flex">
					{Texts.FileManagementView.Sections.Metadata.VersionColumn.PatchFieldLabel}
				</Box>

				<NumberFormField
					className="version-column-input"
					value={props.fileData?.metadata?.version?.patch}
					onChange={handlePatchVerionChange}
				/>
			</Box>
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...FileManagementStore.actionCreators,
		},
		dispatch
	);
};

export default connect(
	(state: ApplicationState) => state.fileManagement,
	mapDispatchToProps
)(VersionColumn);
