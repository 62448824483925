import {
	Container,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import ViewHeader from "../../components/shared/ViewHeader";
import Texts from "../../resources/Texts";
import { Contacts } from "../../resources/Contacts.json";
import "./ContactsView.scss";

interface ContactsViewProps {}

const ContactsView: React.FunctionComponent<ContactsViewProps> = (props) => {
	return (
		<Box className="contactsView" display="flex" flexDirection="column">
			<ViewHeader title={Texts.SolutionGroups.Contacts} />

			<Container>
				<Box display="flex" flexDirection="column" className="contactsView-container">
					<Typography
						variant="h4"
						fontFamily="Poppins"
						className="contactsView-container-title"
					>
						{Texts.ContactsView.Title}
					</Typography>

					<Box className="contacts-tables-container">
						<Typography
							variant="h5"
							fontFamily="Poppins"
							className="contactsView-container-section-title"
						>
							{Texts.ContactsView.VideoNetworksTitle}
						</Typography>

						<TableContainer>
							<Table width="100%" className="contactsTable">
								<TableBody>
									{Contacts.VideoNetworks.Items.map((item) => (
										<TableRow>
											<TableCell width="541px">
												{item.Name || <>&nbsp;</>}
											</TableCell>
											<TableCell width="216px">
												{item.ContactPhoneNumber}
											</TableCell>
											<TableCell>&nbsp;</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>

						<Typography
							variant="h5"
							fontFamily="Poppins"
							className="contactsView-container-section-title"
						>
							{Texts.ContactsView.RegionalNumbersTitle}
						</Typography>

						<TableContainer>
							<Table width="100%" className="contactsTable">
								<TableBody>
									{Contacts.RegionalNumbers.Items.map((item) => (
										<TableRow>
											<TableCell width="541px">
												{item.Name || <>&nbsp;</>}
											</TableCell>
											<TableCell width="216px">
												{item.ContactCity || <>&nbsp;</>}
											</TableCell>
											<TableCell width="160px">
												{item.ContactPhoneNumber || <>&nbsp;</>}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Box>
			</Container>
		</Box>
	);
};

export default ContactsView;
