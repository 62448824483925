import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ICompany } from "../../../resources/Contracts";
import Texts from "../../../resources/Texts";
import { ApplicationState } from "../../../store";
import * as FileManagementStore from "../../../store/FileManagement";
import "./TargetAudienceColumn.scss";

type TargetAudienceColumnProps = FileManagementStore.FileManagementState &
	typeof FileManagementStore.actionCreators & {};

const TargetAudienceColumn: React.FunctionComponent<TargetAudienceColumnProps> = (props) => {
	const handleRemoveItemClick = (companyId: string) => {
		const companies: ICompany[] = props.fileData.permittedCompanies.filter(
			(c) => c.id !== companyId
		);

		props.setFileManagementFile({
			...props.fileData,
			permittedCompanies: companies,
		});
	};

	return (
		<Box
			className="fileManagement-audience-column targetAudience-column"
			display="flex"
			flexDirection="column"
			flex={[0, 1]}
		>
			<Box className="column-title" display="flex" flex={[1, 0]}>
				{Texts.FileManagementView.Sections.Audience.TargetAudience.Title}
			</Box>

			<span className="target-guide">
				{Texts.FileManagementView.Sections.Audience.TargetAudience.GuideText}
			</span>

			{props.fileData?.permittedCompanies?.map?.((company) => (
				<Box
					className="targetAudience-item"
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					flex={[1, 0]}
					key={company.id.toString()}
				>
					<span className="item-name">{company.companyName}</span>

					<IconButton
						className="icon"
						component="span"
						onClick={() => handleRemoveItemClick(company.id)}
					>
						<Close />
					</IconButton>
				</Box>
			))}
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...FileManagementStore.actionCreators,
		},
		dispatch
	);
};

export default connect(
	(state: ApplicationState) => state.fileManagement,
	mapDispatchToProps
)(TargetAudienceColumn);
