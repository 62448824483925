import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { Box } from "@mui/system";
import * as React from "react";
import Texts from "../../../resources/Texts";
import "./FileTaxonomySection.scss";
import FileTaxonomyTree from "./FileTaxonomyTree";

type FileTaxonomySectionProps = {
	defaultSelectedId: string;
};

const FileTaxonomySection: React.FunctionComponent<FileTaxonomySectionProps> = (props) => {
	return (
		<Box
			className="fileManagement-formSection fileManagement-taxonomySection"
			display="flex"
			flexDirection="column"
			flex={1}
		>
			<Box className="fileManagement-section-title" display="flex" alignItems="center">
				<AccountTreeIcon className="icon" />
				{Texts.FileManagementView.Sections.Taxonomy.Title}
			</Box>

			<span className="taxonomy-guide">
				{Texts.FileManagementView.Sections.Taxonomy.GuideText}
			</span>

			<Box className="fileManagement-taxonomy-controls" display="flex" flex={1}>
				<FileTaxonomyTree defaultSelectedId={props.defaultSelectedId} />

				<Box
					className="fileManagement-taxonomy-controls-placeholder"
					display="flex"
					flex={1}
				></Box>
			</Box>
		</Box>
	);
};

export default FileTaxonomySection;
