import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { History } from "history";
import { ApplicationState, reducers } from "./";

export default function configureStore(history: History, initialState?: ApplicationState) {
	const middleware = [thunk, routerMiddleware(history)];

	const rootReducer = combineReducers({
		...reducers,
		router: connectRouter(history),
	});

	const enhancers: any[] = [];
	const windowIfDefined = typeof window === "undefined" ? null : (window as any);
	if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
		enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
	}

	const store = createStore(
		rootReducer,
		initialState,
		compose(applyMiddleware(...middleware), ...enhancers)
	);

	return store;
}
