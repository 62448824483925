import { Button } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import * as Contracts from "../../resources/Contracts";
import Texts from "../../resources/Texts";
import TextFormField from "../shared/formControls/TextFormField";

type AddCategorySectionProps = {
	onAdd: (category: Contracts.ICategory) => void;
};

const AddCategorySection: React.FunctionComponent<AddCategorySectionProps> = ({ onAdd }) => {
	const [file, setFile] = React.useState<string>(null);
	const [selectedFileName, setSelectedFileName] = React.useState<string>(null);
	const [categoryName, setCategoryName] = React.useState<string>(null);

	const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const selectedFile: File = event.target.files?.[0];

		setSelectedFileName(selectedFile?.name);

		if (selectedFile) {
			var reader = new FileReader();

			reader.readAsDataURL(selectedFile);

			reader.onload = function () {
				setFile(reader.result as string);
			};

			reader.onerror = function (error) {
				console.log("Error: ", error);
			};
		}
	};

	const handleAdd = () => {
		onAdd({
			icon: file,
			navigationName: categoryName,
		});
	};

	return (
		<Box display="flex" alignItems="center" marginTop="12px" justifyContent="space-between">
			<Box display="flex" alignItems="center">
				<Box margin="0 6px">
					<TextFormField value={categoryName} onChange={setCategoryName} />
				</Box>

				<Box margin="0 6px">
					<Button variant="contained" component="label" className="button button-select">
						{Texts.CategoryManagementView.SelectButton}
						<input type="file" hidden onChange={handleFileSelect} accept="image/*" />
					</Button>
				</Box>

				<Box margin="0 6px">{file && selectedFileName}</Box>
			</Box>

			<Box display="flex">
				<Button
					variant="contained"
					component="label"
					className="button button-select"
					onClick={handleAdd}
					disabled={!categoryName || !file}
				>
					{Texts.CategoryManagementView.AddButton}
				</Button>
			</Box>
		</Box>
	);
};

export default AddCategorySection;
