import axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource, Method } from "axios";
import * as Contracts from "../resources/Contracts";
import * as Enums from "../resources/Enums";

export class Service {
	private static async callApi<TResponse>(
		apiUrl: string,
		method: Method,
		payload?: any,
		onUploadProgress?: (progressEvent: ProgressEvent) => void,
		cancelTokenSource?: CancelTokenSource
	): Promise<TResponse> {
		let response: AxiosResponse<TResponse>;
		const url: string = `/api${apiUrl}`;

		const requestConfig: AxiosRequestConfig = {
			onUploadProgress: onUploadProgress,
			cancelToken: cancelTokenSource?.token,
			timeout: 120000, // 2min
		};

		switch (method) {
			case "GET":
				response = await axios.get(url, requestConfig);
				break;

			case "POST":
				response = await axios.post(url, payload, requestConfig);
				break;

			case "PUT":
				response = await axios.put(url, payload, requestConfig);
				break;

			case "PATCH":
				response = await axios.patch(url, payload, requestConfig);
				break;

			case "DELETE":
				response = await axios.delete(url, requestConfig);
				break;
		}
		return response.data;
	}

	public static async getUsers(): Promise<any> {
    return Service.callApi<any>("/Navigation/getusers", "GET");
  }

	public static async getCompanies(search?: string): Promise<Contracts.ICompany[]> {
		let url: string = "/companies/getcompanies";

		if (search) {
			url += "?searchTerm=" + search;
		}

		return Service.callApi<Contracts.ICompany[]>(url, "GET");
	}

	public static async getRoles(): Promise<Contracts.IRole[]> {
		return Service.callApi<Contracts.IRole[]>("/roles/getroles", "GET");
	}

	public static async getCategories(): Promise<Contracts.ICategory[]> {
		return Service.callApi<any>("/Anonym/getCategories", "GET");
	}

  // updated promise from Any to contract
  public static async getOpensourceFiles(): Promise<Contracts.IOpensourceFile[]> {
		return Service.callApi<any>("/Opendata/filelist", "GET");
  }

  public static async getOpensourceProducts(): Promise<Contracts.IOpensourceProduct[]> {
		return Service.callApi<any>("/Opendata/productlist", "GET");
  }

	public static async getGroupNavigationNodeId(groupName: string): Promise<number> {
		return await Service.callApi(`/Anonym/getGroupNavigationNodeId/${groupName}`, "GET");
	}

	public static async addNewCategory(value: Contracts.ICategory): Promise<void> {
		await Service.callApi("/Navigation/addCategory", "POST", value);
	}

	public static async addNewChannel(value: Contracts.IChannel): Promise<void> {
		await Service.callApi("/Channel", "POST", value);
	}

	public static async updateCategory(value: Contracts.ICategory): Promise<void> {
		await Service.callApi("/Navigation/updateCategory", "PUT", {
			id: value.id,
			navigationName: value.navigationName,
			icon: value.icon,
		});
	}

	public static async deleteCategory(id: number): Promise<void> {
		await Service.callApi(`/Navigation/deletecategory/${id}`, "GET");
	}

	public static async getFileItemDetail(fileId: string): Promise<any> {
		return await Service.callApi<any>(`/Navigation/getfileinfo/${fileId}`, "GET");
	}

	public static async getTabsByCategory(categoryName: string): Promise<Contracts.ISolutionTab[]> {
		const response = await Service.callApi<Contracts.ISolutionTab[]>(
			`/anonym/GetTabsByCategory/${categoryName}`,
			"GET"
		);

		return response;
	}

	public static async getTabsByCategoryId(id: number): Promise<Contracts.ISolutionTab[]> {
		const response = await Service.callApi<Contracts.ISolutionTab[]>(
			`/anonym/GetTabsByCategoryId/${id}`,
			"GET"
		);

		return response;
	}

	public static async getFoldersStructure(
		rootGroupName: string,
		getDetailItems: boolean = true
	): Promise<Contracts.ISolutionTreeItem> {
		return Service.callApi<Contracts.ISolutionTreeItem>(
			`/anonym/getfolderstructure/${rootGroupName}/${getDetailItems}`,
			"GET"
		);
	}

	public static async getTabItem(tabId: string): Promise<Contracts.ISolutionTreeItem> {
		return Service.callApi<Contracts.ISolutionTreeItem>(
			`/anonym/GetItemsByTab/${tabId}`,
			"GET"
		);
	}

	public static async getDetailItem(
		solutionFolderItemId: string
	): Promise<Contracts.ISolutionTreeItem> {
		return Service.callApi<Contracts.ISolutionTreeItem>(
			`/anonym/getDetailItems/${solutionFolderItemId}`,
			"GET"
		);
	}

	public static async addNewFolder(parentId: string, folderName: string): Promise<void> {
		const requestBody = {
			parentId: Number(parentId),
			navigationName: folderName,
		};

		await Service.callApi("/Navigation/addnavigationitem", "POST", requestBody);
	}

	public static async editFolder(folderId: string, folderName: string): Promise<void> {
		const requestBody = {
			nodeId: Number(folderId),
			nodeName: folderName,
		};

		await Service.callApi("/Navigation/updateFolder", "PUT", requestBody);
	}

	public static async getCurrentUser(): Promise<Contracts.IUser> {
		return Service.callApi<Contracts.IUser>("/auth/islogin", "GET");
	}

	public static async addFileToDownloadCart(fileName: string, fileLink: string): Promise<string> {
		const requestBody = {
			fileName: fileName,
			fileLink: fileLink,
		};

		return Service.callApi<string>("/Queue/addmessage", "POST", requestBody);
	}

	public static async getDownloadCartContent(): Promise<Contracts.IFileDownloadData[]> {
		return Service.callApi<Contracts.IFileDownloadData[]>("/Queue/getmessages", "GET");
	}

	// FILES
	public static async deleteFile(nodeId: string): Promise<any> {
		return Service.callApi(`/Files/deleteFile/${nodeId}`, "DELETE");
	}
	public static async deleteFolder(nodeId: string): Promise<any> {
		return Service.callApi(`/Files/deleteFolder/${nodeId}`, "DELETE");
	}

	public static async getFileDetailMetadata(
		fileName: string
	): Promise<Contracts.IDetailFileMetadata> {
		return Service.callApi<Contracts.IDetailFileMetadata>(
			`/Anonym/getdetails/${fileName}`,
			"GET"
		);
	}

	public static async getFileDownloadLink(nodeId: string): Promise<Contracts.IFileDownloadData> {
		return Service.callApi<Contracts.IFileDownloadData>(`/Anonym/getLink/${nodeId}`, "GET");
	}

  public static async getDocumentLink(Filename: string): Promise<Contracts.IDocumentLink> {
		return Service.callApi<Contracts.IDocumentLink>(`/Opendata/getdocumentlink/${Filename}`, "GET");
	}

	public static async getFileDetails(nodeId: string): Promise<Contracts.IFileDetailResponse> {
		return Service.callApi<Contracts.IFileDetailResponse>(
			`/Anonym/getdetails/${nodeId}`,
			"GET"
		);
	}

	public static async getFileDetailsByUniqueId(
		uniqueId: string
	): Promise<Contracts.IFileDetailResponse> {
		return Service.callApi<Contracts.IFileDetailResponse>(
			`/Anonym/getdetailsbyuniqueid/${uniqueId}`,
			"GET"
		);
	}

	public static async getPublicDownloadUrl(
		nodeId: string
	): Promise<string> {
		return Service.callApi<string>(
			`/Anonym/getPublicDownloadUrl/${nodeId}`,
			"GET"
		);
	}

	public static async getUploadSasLink(): Promise<any> {
		return Service.callApi("/Files/getUploadLink", "GET");
	}

	public static async uploadFile(
		file: File,
		onUploadProgress: (progressEvent: ProgressEvent) => void,
		cancelTokenSource: CancelTokenSource
	): Promise<Contracts.IFileDownloadInformation> {
		const data: FormData = new FormData();

		data.append("File", file);

		return Service.callApi<Contracts.IFileDownloadInformation>(
			"/Files/upload",
			"POST",
			data,
			onUploadProgress,
			cancelTokenSource
		);
	}

	public static async uploadFileMetadata(
		fileUniqueId: string,
		fileName: string,
		fileTitle: string,
		description: string,
		parentId: number,
		fileType: Enums.FileType,
		version: string,
		permission: Enums.FilePermission,
		permittedCompanies: Contracts.ICompany[],
		permittedRoles: Contracts.IRole[]
	): Promise<void> {
		const postMetadata: any = {
			FileUniqueId: fileUniqueId,
			FileName: fileName,
			Title: fileTitle,
			Description: description,
			ParentId: parentId,
			Type: fileType,
			Version: version,
			Permission: permission,
			PermittedCompanies: permittedCompanies,
			PermittedRoles: permittedRoles,
		};

		await Service.callApi<Contracts.IFileUploadProgress>(
			"/Files/updateMetadata",
			"POST",
			postMetadata
		);
	}

	public static async getFileUploadProgress(
		fileName: string
	): Promise<Contracts.IFileUploadProgress> {
		return Service.callApi<Contracts.IFileUploadProgress>(
			`/Files/uploadProgress/${fileName}`,
			"GET"
		);
	}

	public static async cancelFileUpload(fileName: string): Promise<void> {
		const requestBody = {
			fileName: fileName,
		};

		await Service.callApi<Contracts.IFileUploadProgress>(
			"/Files/cancelUpdate",
			"POST",
			requestBody
		);
	}
}
