import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import { ISolutionTreeItem } from "../../resources/Contracts";
import "./SolutionsBrowserDetailTabs.scss";

type SolutionsBrowserDetailTabsProps = {
	tabItems: ISolutionTreeItem[];
	value: string;
	onChange: (id: string) => void;
};

const SolutionsBrowserDetailTabs: React.FC<SolutionsBrowserDetailTabsProps> = (props) => {
	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		props.onChange(newValue);
	};

	return (
		<Tabs key={0}
			value={props.value}
			onChange={handleChange}
			className="solutionsBrowser-detail-tabs"
			TabIndicatorProps={{
				hidden: true,
			}}
		>
			{props.tabItems
				? props.tabItems.map((tab, index) => (
					<Tab key={index} label={tab.text} className="tab" value={tab.id} />
				  ))
				: null}
		</Tabs>
	);
};

export default SolutionsBrowserDetailTabs;
