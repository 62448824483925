import { Box } from "@mui/system";
import * as React from "react";
import Texts from "../../../resources/Texts";
import * as FileManagementStore from "../../../store/FileManagement";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./TitleColumn.scss";
import TextFormField from "../../shared/formControls/TextFormField";

type TitleColumnProps = FileManagementStore.FileManagementState &
	typeof FileManagementStore.actionCreators & {};

const TitleColumn: React.FunctionComponent<TitleColumnProps> = (props) => {
	const handleTitleChange = (value: string) => {
		props.setFileManagementFile({
			...props.fileData,
			metadata: {
				...props.fileData?.metadata,
				title: value,
			},
		});
	};

	const handleDescriptionChange = (value: string) => {
		props.setFileManagementFile({
			...props.fileData,
			metadata: {
				...props.fileData?.metadata,
				description: value,
			},
		});
	};

	return (
		<Box
			className="fileManagement-metadata-column title-column"
			display="flex"
			flexDirection="column"
			flex={[0, 1]}
		>
			<Box className="column-title" display="flex" flex={[1, 0]}>
				{Texts.FileManagementView.Sections.Metadata.TitleColumn.Title}
			</Box>

			<TextFormField value={props.fileData?.metadata?.title} onChange={handleTitleChange} />

			<TextFormField
				multiline
				value={props.fileData?.metadata?.description}
				label={Texts.FileManagementView.Sections.Metadata.TitleColumn.DescriptionFieldLabel}
				onChange={handleDescriptionChange}
			/>
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...FileManagementStore.actionCreators,
		},
		dispatch
	);
};

export default connect(
	(state: ApplicationState) => state.fileManagement,
	mapDispatchToProps
)(TitleColumn);
