import * as React from 'react';
import clsx from 'clsx';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import CustomTextField from '../CustomTextField';
import { IChannelCompany } from '../../../resources/Contracts';
import { Typography } from '@mui/material';
import { Routing } from '../../../resources/Routes';

export default function DeleteChannelModal({selectedChannelUniqueId}) {
  const [open, setOpen] = React.useState(false);
  const apiDeleteChannel = `/api/Channel/${selectedChannelUniqueId}`;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDelete = () => 
  {
    console.log(selectedChannelUniqueId);

    if(selectedChannelUniqueId!== null)
    {
        const requestOptions = {
            method: 'DELETE'
        };
        fetch(apiDeleteChannel, requestOptions);
        window.location.replace(Routing.getChannelManagementViewUrl());
    }
  }

  const handleCloseReset = () =>
  {
    setOpen(false);
  }

  return (
    <div>
      <TriggerButton sx={{ marginLeft: 10, backgroundColor: "#ff3333", border: '1px solid #FF3333' }} type="button" onClick={handleOpen}>
        Delete
      </TriggerButton>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent sx={{ width: 900 }}>
          <h2 id="unstyled-modal-title" className="modal-title">
            Delete Channel      
          </h2>
          <p id="unstyled-modal-description" className="modal-description">
            You are about to delete this channel, its permissions and its access keys.  Customers will no longer have access to it.
          </p>
          <h3 id="unstyled-modal-confirm-delete" className="modal-description" style={{padding: '16px 0px', color: '#b30000'}}>
            <b>Are you really sure?</b>
          </h3>
          <div>
             <TriggerButton type="button" style={{backgroundColor: '#FF3333', border: '1px solid #FF3333'}} onClick={handleDelete}>
              Ok
            </TriggerButton>
            <TriggerButton type="button" style={{backgroundColor: 'grey', border: '1px solid grey'}} onClick={handleCloseReset}>
              Cancel
            </TriggerButton>
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'base-Backdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const blue = {
  200: '#99CCFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0066CC',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')({
  fontFamily: 'IBM Plex Sans, sans-serif',
  fontWeight: 500,
  textAlign: 'start',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  gap: '8px',
  overflow: 'hidden',
  backgroundColor: '#fff', // Static background color
  borderRadius: '8px',
  border: `1px solid ${grey[200]}`, // Static border color
  boxShadow: '0 4px 12px rgb(0 0 0 / 0.2)', // Static shadow
  color: grey[900], // Static text color

  '& .modal-title': {
    margin: 0,
    lineHeight: '1.5rem',
    marginBottom: '8px',
  },

  '& .modal-description': {
    margin: 0,
    lineHeight: '1.5rem',
    fontWeight: 400,
    color: grey[800], // Static description color
    marginBottom: '4px',
  },
});

const TriggerButton = styled('button')({
  fontFamily: 'IBM Plex Sans, sans-serif',
  fontWeight: 600,
  width: 100,
  marginRight: '16px',
  fontSize: '0.875rem',
  lineHeight: 1.5,
  padding: '8px 16px',
  transition: 'all 150ms ease',
  cursor: 'pointer',
  background: '#3380FF',
  border: '1px solid #3380FF',
  color: 'white',

  '&:hover': {
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.35)',
  }
});
