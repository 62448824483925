import * as SelectedSolutions from "./SelectedSolution";
import * as CurrentUser from "./CurrentUser";
import * as AlertStore from "./AlertStore";
import * as FileManagement from "./FileManagement";

// The top-level state object
export interface ApplicationState {
	selectedSolutions: SelectedSolutions.SelectedSolutionState;
	currentUser: CurrentUser.CurrentUserState;
	alerts: AlertStore.AlertState;
	fileManagement: FileManagement.FileManagementState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
	selectedSolutions: SelectedSolutions.reducer,
	currentUser: CurrentUser.reducer,
	alerts: AlertStore.reducer,
	fileManagement: FileManagement.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
	(dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
