import { Table, TableBody, TableContainer } from "@mui/material";
import * as React from "react";
import * as Contracts from "../../resources/Contracts";
import CategoryTableRow from "./CategoryTableRow";

type CategoryTableProps = {
	categories: Contracts.ICategory[];
	onDelete: (id: number) => void;
	onSave: (value: Contracts.ICategory) => void;
};

const CategoryTable: React.FunctionComponent<CategoryTableProps> = ({
	categories,
	onDelete,
	onSave,
}) => {
	return (
		<TableContainer>
			<Table width="100%" className="contactsTable">
				<TableBody>
					{categories?.map((item, index) => (
						<CategoryTableRow
							category={item}
							onDelete={onDelete}
							onSave={onSave}
							key={`categoryTable-item_${index}`}
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default CategoryTable;
