import * as React from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {styled} from "@mui/system";
import Cookies from "js-cookie"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import LoggedInSynamediaRestrictedComponent from "../shared/formControls/LoggedInSynamediaRestrictedComponent";
import downloadLink from "./downloadlink.png"
import downloadDialog from "./downloadDialog.png"
import overwriteFile from "./overwriteFile.png"

const NewFeaturesDialog: React.FC = () => {

	const [newFeaturesDialogOpen, setNewFeaturesDialogOpen] = React.useState<boolean>(false);
	const sdpVersionCookieName = 'Software.Portal.VersionNumber'
	const sdpVersionNumber = '1';

	React.useEffect(() => {

		if (Cookies.get(sdpVersionCookieName) !== sdpVersionNumber) setNewFeaturesDialogOpen(true);
	}, []);

	const handleCloseNewFeaturesDialog = () => {
		Cookies.set(sdpVersionCookieName, sdpVersionNumber, { path: '/' });
		setNewFeaturesDialogOpen(false);
	};

	return (
		<LoggedInSynamediaRestrictedComponent>
				<Dialog
					open={newFeaturesDialogOpen}
					fullWidth={true}
					maxWidth={"md"}
				>
					<DialogContent>
						<div style={{textAlign: "center", fontWeight: 'bold', fontSize: '32px', color: '#3380FF'}}>New Features!</div>
						<div style={{display: 'flex', flexDirection: 'column', gap: '24px'}}>
							<div style={{display: 'flex', alignItems: 'center', minHeight: '64px'}}>
								<div style={{marginLeft: '8px', paddingBottom: '8px'}}>
									<h2>Help</h2>
									There is a new help menu under the "Services & Support Menu" where you can view this
									information again
									once this page has been dismissed.
									<h2>Software Download APIs</h2>
									<p>The Software Download Portal now supports <i>Software Download APIs</i> that can
										be integrated with your product
										so that you can automatically download new updates etc. without the customer
										having to manually download updates.
									</p>
									<h2>Download Link</h2>
									<p>For software/documentation which have been marked as <b>public</b>, you can now
										get a direct download link that can be shared.
										For example, if you have a PDF user guide for a product, you can get a download
										URL and convert it to a QR
										code to be shared with your customers.</p>
									<img src={downloadLink} alt="" style={{width: '600px', height: 'auto'}}/>
									<p>If the item is <b>public</b> then a new download link will be displayed as shown
										above.
										Click on that in order to get the download URL.</p>
									<img src={downloadDialog} alt="" style={{width: '700px', height: 'auto'}}/>
									<p>You can then click on <ContentCopyIcon/> to copy the URL to the clipboard.</p>
									<h2>Overwrite A File</h2>
									<p>If you've shared a download link e.g. as a QR code to a user guide, and you want
										to update
										that file without generating a new URL, you can overwrite the
										file.<br/>
										<b>Note: The old version of the file will no longer be accessible so you must
										download/re-upload as
										a new file first if you wish to retain it. </b> <br/>
										Click on <UploadFileIcon/> to upload the new version.
									</p>
									<img src={overwriteFile} alt="" style={{width: '700px', height: 'auto'}}/>
									<p style={{marginTop: '32px', fontWeight: 'bold', color: 'RoyalBlue'}}><i>For further details on any of the above please contact Neil Thomas
										(neithoma@synamedia.com)</i></p>
								</div>
							</div>
							<TriggerButton onClick={handleCloseNewFeaturesDialog}>Close</TriggerButton>
						</div>
					</DialogContent>
				</Dialog>
				</LoggedInSynamediaRestrictedComponent>
	);
};

const TriggerButton = styled('button')({
	fontFamily: 'IBM Plex Sans, sans-serif',
	fontWeight: 600,
	maxWidth: '80px',
	margin: 'auto',
	fontSize: '0.875rem',
	lineHeight: 1.5,
	padding: '8px 16px',
	transition: 'all 150ms ease',
	cursor: 'pointer',
	background: '#3380FF',
	border: '1px solid #3380FF',
	color: 'white',

	'&:hover': {
		boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.35)',
	}
});

export default NewFeaturesDialog;