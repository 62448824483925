import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Contracts from "../../resources/Contracts";
//import { Service } from "../../services/Service";
import { actionCreators as AlertStoreActionCreators } from "../../store/AlertStore";
import { ApplicationState } from "../../store";
import { CurrentUserState } from "../../store/CurrentUser";
import { handleErrorMessage } from "../../utils/utils";
import "./MyChannelsContainer.scss";
import Texts from "../../resources/Texts";
import { IChannel, IChannelCompany } from "../../resources/Contracts";
import MyChannels from "../channelManagement/ChannelList/MyChannels";
import GetAccessKeyModal from "./GetAccessKey/GetAccessKeyModal";
import InfoIcon from '@mui/icons-material/Info';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import clsx from 'clsx';
//type MyChannelsContainerProps = typeof AlertStoreActionCreators & {};

//const MyChannelsContainer: React.FunctionComponent<MyChannelsContainerProps> = (props) => {

type MyChannelsContainerProps = typeof AlertStoreActionCreators & CurrentUserState & {};

const MyChannelsContainer: React.FC<React.PropsWithChildren<MyChannelsContainerProps>> = (props) => {
	const [channels, setChannels] = React.useState<Contracts.IChannel[]>([]);
	
	// Sub-component selections
	const [selectedChannel, setSelectedChannel] = React.useState("");
	const [helpModalOpen, setHelpModalOpen] = React.useState(false);

	let myCompany = props.user.companyId;

	const handleHelpOpen = () => setHelpModalOpen(true);
	const handleHelpClose = () => setHelpModalOpen(false);

	return (
		<Box className="myChannels-form-container" display="flex" flexDirection="column">
		<Box>
			<Typography
				variant="h5"
				component="div"
				className="myChannels-form-title"
				fontFamily="Poppins"
			>
				{Texts.MyChannelsView.ViewTitle}
				<InfoIcon style={{float: "right", color: "#3380FF", paddingTop: '4px', cursor: "pointer"}} onClick={handleHelpOpen}>Help</InfoIcon>
			</Typography>


				<Box
					className="myChannels-form-container"
					display="flex"
					flexDirection="column"
				>
				<Box
					className="myChannels-form-container"
					display="flex"
					flexDirection="row"
				>
				</Box>
				<MyChannels setSelectedChannel={setSelectedChannel} myCompanyId={myCompany}/>
					{ selectedChannel !== "" && <GetAccessKeyModal selectedChannelUniqueId={selectedChannel} myCompanyId={myCompany} /> }

				</Box>
		</Box>
			<Modal
				open={helpModalOpen}
				onClose={handleHelpClose}
				slots={{ backdrop: StyledBackdrop }}
				sx={{ width: 900 }}
			>
				<ModalContent>
					<div className="modal-description">
						<h1 style={{textAlign: 'center'}}>Automatic Software Download</h1>
						<p>Some products offer automatic download of software updates so that you do not have to manually download them from the <i>Software Download Portal</i>.

						If you have been given access to <i>Download Channels</i> for automatic software download then they will appear here. </p>
						<p>In order to download the software updates you must first create <i>Channel Access Keys</i> and configure them in your product. (Please refer to your product documentation on how to do this).</p>
						<p>Select a channel and then click on the ‘<i>Manage Channel Access Keys</i>’ button.
						The screen will then show you details of the channel and what it has access to, along with any channel access keys that have already been created.
						If you want to create a key then click on '<i>Request A New Channel Access Key</i>'. You can then copy the <i>Download Channel </i> and <i>Access Key</i> to your product by clicking on the copy icons.</p>
						<p>Up to 5 keys may be created per channel. Keys can be deleted by clicking on ‘<i>Delete</i>’.</p>
					</div>
					<TriggerButton onClick={handleHelpClose}>Close</TriggerButton>
				</ModalContent>
			</Modal>
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...AlertStoreActionCreators,
		},
		dispatch
	);
};

//export default connect(null, mapDispatchToProps)(MyChannelsContainer);
export default connect((state: ApplicationState) => state.currentUser, mapDispatchToProps)(MyChannelsContainer);

const Backdrop = React.forwardRef<
	HTMLDivElement,
	{ open?: boolean; className: string }
>((props, ref) => {
	const { open, className, ...other } = props;
	return (
		<div
			className={clsx({ 'base-Backdrop-open': open }, className)}
			ref={ref}
			{...other}
		/>
	);
});

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')({
	fontFamily: 'IBM Plex Sans, sans-serif',
	fontWeight: 500,
	textAlign: 'start',
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
	padding: '24px',
	gap: '8px',
	overflow: 'hidden',
	backgroundColor: '#fff', // Static background color
	borderRadius: '8px',
	border: `1px solid white`, // Static border color
	boxShadow: '0 4px 12px rgb(0 0 0 / 0.2)', // Static shadow
	color: 'black', // Static text color

	'& .modal-title': {
		margin: 0,
		lineHeight: '1.5rem',
		marginBottom: '8px',
	},

	'& .modal-description': {
		margin: 0,
		lineHeight: '1.5rem',
		fontWeight: 400,
		color: '#303740', // Static description color
		marginBottom: '4px',
	},

	'&:focus': {
		outline: 'none', // Removes the default blue focus outline
	},
});

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
	margin: auto;
`;

const TriggerButton = styled('button')({
	fontFamily: 'IBM Plex Sans, sans-serif',
	fontWeight: 600,
	width: 100,
	marginRight: '16px',
	fontSize: '0.875rem',
	lineHeight: 1.5,
	padding: '8px 16px',
	transition: 'all 150ms ease',
	cursor: 'pointer',
	background: '#3380FF',
	border: '1px solid #3380FF',
	color: 'white',
	margin: 'auto',

	'&:hover': {
		boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.35)',
	}
});