import PublishIcon from "@mui/icons-material/Publish";
import { Button, LinearProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios, { CancelToken, CancelTokenSource } from "axios";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IFileDownloadInformation } from "../../../resources/Contracts";
import { FilePermission, FileType } from "../../../resources/Enums";
import Texts from "../../../resources/Texts";
import { Service } from "../../../services/Service";
import { ApplicationState } from "../../../store";
import { actionCreators as AlertStoreActionCreators } from "../../../store/AlertStore";
import * as FileManagementStore from "../../../store/FileManagement";
import { handleErrorMessage } from "../../../utils/utils";
import "./FileUploadSection.scss";

import { AbortController } from "@azure/abort-controller";
import { BlobServiceClient, BlockBlobParallelUploadOptions } from "@azure/storage-blob";

import { v4 as uuidv4 } from "uuid";

type FileUploadSectionProps = FileManagementStore.FileManagementState &
	typeof FileManagementStore.actionCreators &
	typeof AlertStoreActionCreators & {
		editFileTitle: string;
		onUploadStarted: () => void;
		onUploadFinished: () => void;
		existingFileGuid: string;
	};

const FileUploadSection: React.FunctionComponent<FileUploadSectionProps> = (props) => {
	const [uploadActive, setUploadActive] = React.useState<boolean>(false);
	const [uploadFinished, setUploadFinished] = React.useState<boolean>(false);
	const [uploadProgress, setUploadProgress] = React.useState<number>(0);
	const [file, setFile] = React.useState<File>(null);
	const [cancelTokenSource, setCancelTokenSource] = React.useState<CancelTokenSource>(null);
	const [progressText, setProgressText] = React.useState<string>(null);
	const [fileGuid, setFileGuid] = React.useState(props.existingFileGuid);

	const controller = new AbortController();
	const uploadAbortSignal = controller.signal;

	const uploadCompleted = async () => {
		const formData = new FormData();
		formData.append("fileName", file.name);

		const response = await axios.post("/api/Files/FileUploadComplete", null, {
			params: {
				fileName: file.name,
				fileUniqueId: fileGuid,
			},
			data: formData,
		});
		const data = response.data;
		if (data.isSuccess) {
			setProgressText(Texts.FileManagementView.Sections.Upload.Progress.Finished);
			props.onUploadFinished();
			setUploadFinished(true);
			setUploadProgress(100);

			const downloadInfo: IFileDownloadInformation = {
				fileUniqueId: fileGuid,
				mD5Checksum: data.data["mD5Checksum"],
				size: data.data["size"],
				timeStamp: data.data["timeStamp"],
				uploadedBy: data.data["uploadedBy"],
			};

			props.setFileManagementFile({
				...props.fileData,
				fileName: file.name,
				metadata: {
					...props.fileData?.metadata,
					downloadInfo: downloadInfo,
					fileType: FileType.Software,
				},
				permission: FilePermission.Registered,
			});
		}
	};

	if (props.editFileTitle) {
		return (
			<Box
				className="fileManagement-formSection fileManagement-uploadSection"
				display="flex"
				flexDirection="column"
			>
				{props.fileData?.fileName}
			</Box>
		);
	}

	const cancelFileUpload = async (): Promise<void> => {
		try {
			if (cancelTokenSource) {
				cancelTokenSource.cancel();
			}

			if (uploadActive) {
				controller.abort();
			}

			setUploadActive(false);
			setUploadProgress(null);
			setUploadFinished(false);
			setFile(null);

			props.setFileManagementFile({
				...props.fileData,
				fileName: null,
				metadata: {
					...props.fileData?.metadata,
					downloadInfo: null,
				},
			});
		} catch (error) {
			props.addErrorAlert(handleErrorMessage(error));

			setUploadProgress(null);
		}
	};
		
	const azureFileUpload = async () => {
		const downloadInfo: IFileDownloadInformation = {
			fileUniqueId: fileGuid,
			mD5Checksum: "",
			size: 0,
			timeStamp: new Date(Date.now()),
			uploadedBy: "",
		};

		props.setFileManagementFile({
			...props.fileData,
			fileName: file.name,
			metadata: {
				...props.fileData?.metadata,
				downloadInfo: downloadInfo,
				fileType: FileType.Software,
			},
			permission: FilePermission.Registered,
		});

		const sas = await Service.getUploadSasLink();
		const blobServiceClient = new BlobServiceClient(sas.uri);

		const containerClient = blobServiceClient.getContainerClient(sas.container);
		if (!await containerClient.exists()) {
			console.log(`Container '${sas.container}' was not found.`);
			return;
		}

		const blobClient = containerClient.getBlockBlobClient(fileGuid);

		const options: BlockBlobParallelUploadOptions = {
			concurrency: 20,
			blobHTTPHeaders: { blobContentType: file.type },
			onProgress: async (ev) => {
				const percentage: number = (ev.loadedBytes / file.size) * 100;
				setUploadProgress(percentage);
			},
			abortSignal: uploadAbortSignal
		};

		props.onUploadStarted();
		await blobClient.uploadData(file, options)
			.catch((err) => {
				console.log(err);
				if (err.name === 'AbortError') {
					console.log('Upload aborted');
				} else {
					throw err;
				}
			});

		await uploadCompleted();
	};

	const azureFileUpdate = async () => {
		const sas = await Service.getUploadSasLink();
		const blobServiceClient = new BlobServiceClient(sas.uri);

		const containerClient = blobServiceClient.getContainerClient(sas.container);
		if (!await containerClient.exists()) {
			console.error(`Container '${sas.container}' was not found.`);
			return;
		}

		const blobClient = containerClient.getBlockBlobClient(fileGuid);

		// get current metadata and tags as we want to copy these across to the new blob
		const existingProperties = await blobClient.getProperties();
		const existingMetadata = existingProperties.metadata;

		const tagsResponse = await blobClient.getTags();
		const existingTags = tagsResponse.tags;

		const options: BlockBlobParallelUploadOptions = {
			concurrency: 20,
			blobHTTPHeaders: { blobContentType: file.type },
			onProgress: async (ev) => {
				const percentage: number = (ev.loadedBytes / file.size) * 100;
				setUploadProgress(percentage);
			},
			abortSignal: uploadAbortSignal
		};

		props.onUploadStarted();
		await blobClient.uploadData(file, options)
			.catch((err) => {
				console.log(err);
				if (err.name === 'AbortError') {
					console.log('Upload aborted');
				} else {
					throw err;
				}
			});

		// update tags and metadata on the new blob, this will also calculate the new md5 checksum
		await axios.post(
			"/api/Files/updateTagsAndMetadata",
			{...existingMetadata, ...existingTags, fileUniqueId: fileGuid}
		);

		setProgressText(Texts.FileManagementView.Sections.Upload.Progress.Finished);
		setUploadFinished(true);
		setUploadProgress(100);
		props.onUploadFinished();
	};


	const uploadFile = async (): Promise<void> => {
		setUploadActive(true);

		if (props.existingFileGuid) {
			await azureFileUpdate();
		} else {
			await azureFileUpload();
		}
	};

	const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const selectedFile: File = event.target.files?.[0];

		setFile(selectedFile);

		props.setFileManagementFile({
			...props.fileData,
			fileName: selectedFile.name,
		});

		setFileGuid(fileGuid ? fileGuid : uuidv4());
	};

	const handleUploadClick = async (): Promise<void> => {
		await uploadFile();
	};

	const handleCancelClick = async (): Promise<void> => {
		await cancelFileUpload();
	};

	return (
		<Box
			className="fileManagement-formSection fileManagement-uploadSection"
			display="flex"
			flexDirection="column"
		>
			<Box className="fileManagement-section-title" display="flex" alignItems="center">
				<PublishIcon className="icon" />
				{Texts.FileManagementView.Sections.Upload.Title}
			</Box>

			<Box className="fileManagement-upload-controls" display="flex" alignItems="center">
				<Button
					variant="contained"
					component="label"
					className="button button-select"
					disabled={uploadActive || uploadFinished}
				>
					{Texts.FileManagementView.Sections.Upload.SelectButton}
					<input
						type="file"
						disabled={uploadActive || uploadFinished}
						hidden
						onChange={handleFileSelect}
					/>
				</Button>

				{file && (
					<>
						{props.fileData?.fileName}

						{uploadFinished ? (
							<Box
								component="span"
								className="uploadFinished-label"
								sx={{ marginLeft: "15px", fontWeight: "bold" }}
							>
								{Texts.FileManagementView.Sections.Upload.UploadFinished}
							</Box>
						) : (
							<>
								<Button
									variant="contained"
									component="label"
									className="button button-upload"
									onClick={handleUploadClick}
									disabled={uploadActive || uploadFinished}
								>
									<PublishIcon /> {Texts.Buttons.Upload}
								</Button>
								{ !props.existingFileGuid &&
								<Button
									variant="contained"
									component="label"
									className="button button-cancel"
									onClick={handleCancelClick}
								>
									{Texts.Buttons.Cancel}
								</Button>
								}
							</>
						)}
					</>
				)}
			</Box>

			{(uploadActive || uploadFinished) && (
				<Box
					className="fileManagement-upload-progress"
					display="flex"
					flexDirection="column"
					flex={1}
				>
					{progressText && <span>{progressText}</span>}
					<Box sx={{ display: "flex", alignItems: "center" }} flex={1}>
						<Box sx={{ width: "100%", mr: 1 }}>
							<LinearProgress
								className={`fileUpload-progressBar ${
									uploadFinished ? "finished" : "inProgress"
								}`}
								color={
									progressText ===
									Texts.FileManagementView.Sections.Upload.Progress.Failed
										? "error"
										: "primary"
								}
								variant="determinate"
								value={uploadProgress || 0}
							/>
						</Box>
						<Box sx={{ minWidth: 35 }}>
							<Typography variant="body2">
								{`${Math.round(uploadProgress)}%`}
							</Typography>
						</Box>
					</Box>
				</Box>
			)}
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...FileManagementStore.actionCreators,
			...AlertStoreActionCreators,
		},
		dispatch
	);
};

export default connect(
	(state: ApplicationState) => state.fileManagement,
	mapDispatchToProps
)(FileUploadSection);
