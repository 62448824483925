import * as React from 'react';
import clsx from 'clsx';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import CustomTextField from '../CustomTextField';
import { IChannel, IChannelCompany, IChannelPermission, IAccessKey } from '../../../resources/Contracts';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Routing } from '../../../resources/Routes';

export default function ViewChannelModal({selectedChannelUniqueId}) {
  const [open, setOpen] = React.useState(false);

  // UI element state
  const [channelName, setChannelName] = React.useState("");
  const [channelDescription, setChannelDescription] = React.useState("");
  const [channelIsEnabled, setChannelIsEnabled] = React.useState("");
  const [channelIsBlocked, setChannelIsBlocked] = React.useState("");
  const [channelUniqueId, setChannelUniqueId] = React.useState("");
  const [channelSharedPath, setChannelSharedPath] = React.useState("");
  const [channelNavigationId, setChannelNavigationId] = React.useState(0);

  // Data state
  const [channelPermissions, setChannelPermissions] = React.useState<IChannelPermission[]>([]);
  const [channelCompanies, setChannelCompanies] = React.useState<IChannelCompany[]>([]);
  const [channelAccessKeys, setChannelAccessKeys] = React.useState<IAccessKey[]>([]);
  const [channels, setChannels] = React.useState<IChannel[]>([]);
  
  
  // API urls
  const apiGetChannel = `/api/Channel/${selectedChannelUniqueId}`;
  const apiGetAccessKeys =  `/api/Channel/accesskeys/${selectedChannelUniqueId}`;
  const apiGetChannelPermissions = `/api/Channel/permissions/${selectedChannelUniqueId}`;
  const apiGetChannelCompanies = `/api/Channel/company/permissions/${selectedChannelUniqueId}`;
  const apiResolveCompanyNames = '/api/Channel/augment/companies';

  async function fetchChannel() {
    const response = await fetch(apiGetChannel);
    if(response.ok)
    {
      const channel = await response.json();
      return channel[0];
    }
    return [];
  }

  async function fetchChannelPermissions() {
    const response = await fetch(apiGetChannelPermissions);
    if(response.ok)
    {
      const channelPermissions = await response.json();
      return channelPermissions;
    }
    return [];
  }

  async function fetchChannelCompanies() {
    const response = await fetch(apiGetChannelCompanies);
    if(response.ok)
    {
      const channelCompanies = await response.json();
      return channelCompanies.sort((a, b) => b.companyName.toLowerCase() < a.companyName.toLowerCase());
    }
    return [];
  }

  async function fetchChannelAccessKeys() {
    const response = await fetch(apiGetAccessKeys);
    if(response.ok)
    {
      const channelPermissions = await response.json();
      return channelPermissions;
    }
    return [];
  }

  const lookupCompany = (companyId, channelCompanies) => {
    const company = channelCompanies.filter(c => c.id === companyId);
    return company.length > 0 ? company[0].companyName : 'Error - unknown company';
  }

  const handleOpen = async () => {
    const getChannel = await fetchChannel();
    const getAccessKeys = await fetchChannelAccessKeys();
    const getChannelPermissions = await fetchChannelPermissions();
    const getChannelCompanies = await fetchChannelCompanies();

    
    if(getChannel !== null) {
      setChannelName(getChannel.name);
      setChannelDescription(getChannel.description);
      setChannelUniqueId(getChannel.channelUniqueId);
      setChannelIsEnabled(getChannel.isEnabled);
      setChannelIsBlocked(getChannel.isBlocked);
      setChannelNavigationId(getChannel.navigationId);
      setChannelSharedPath(getChannel.resolvedNavigationNode);
    }
    
    // set data state
    if(getAccessKeys !== null) setChannelAccessKeys(getAccessKeys);
    if(getChannelPermissions != null) setChannelPermissions(getChannelPermissions);
    if(getChannelCompanies != null) setChannelCompanies(getChannelCompanies);


    setOpen(true);
  }
  
  const handleClose = () => setOpen(false);
  
  const handleCloseReset = () =>
  {
    setOpen(false);
  }

  return (
    <div>
      <TriggerButton sx={{ marginRight: 2 }} type="button" onClick={handleOpen}>
        View
      </TriggerButton>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent sx={{ width: 900, overflow: 'auto', maxHeight: '90vh' }}>
          <h2 id="unstyled-modal-title" className="modal-title">{channelName}</h2>
          <p id="unstyled-modal-description" className="modal-description">{channelDescription}</p>
          <p id="unstyled-modal-description" className="modal-description" style={{marginTop: '16px'}}><b>Shared Path: </b>{channelSharedPath}</p>

          <p><b>Permissions</b></p>
          {channelCompanies !== null && (
          <TableContainer sx={{minHeight: 100}}>
			      <Table width="100%" className="channelCompaniesTable">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '450px', position: 'sticky', top: 0, zIndex: 1, background: 'powderblue' }}><b>Company</b></TableCell>
                  <TableCell style={{ width: '450px', position: 'sticky', top: 0, zIndex: 1, background: 'powderblue' }}><b>Domain</b></TableCell>
                </TableRow>
              </TableHead>
				      <TableBody>
					      {channelCompanies.map((item, id) => (
                  <TableRow key={`channelCompaniesTable-item_${id}`}>
                    <TableCell style={{ width: '450px'}}>
                      {item.companyName}
                    </TableCell>
                    <TableCell style={{ width: '450px'}}>
                      {item.domain }                    
                    </TableCell>
                  </TableRow>
					      ))}
				      </TableBody>
			      </Table>
		      </TableContainer>
          )}

          <p><b>Access Keys</b></p>
        {channelAccessKeys !== null && (
          <TableContainer sx={{minHeight: 100}}>
			      <Table width="100%" className="channelAccessKeysTable">
              <TableHead>
                <TableRow style={{background: 'powderblue'}}>
                  <TableCell style={{ width: '450px'}}><b>Company</b></TableCell>
                  <TableCell style={{ width: '450px'}}><b>Expires</b></TableCell>
                </TableRow>
              </TableHead>
				      <TableBody>
					      {channelAccessKeys.map((item, channelUniqueId) => (
                  <TableRow key={`channelAccessKeysTable-item_${channelUniqueId}`}>
                    <TableCell style={{ width: '450px'}}>
                      {lookupCompany(item.companyId, channelCompanies)}
                    </TableCell>
                    <TableCell style={{ width: '450px'}}>
                      {(new Date(item.expirationDate)).toUTCString()}
                    </TableCell>
                  </TableRow>
					      ))}
				      </TableBody>
			      </Table>
		      </TableContainer>
        )}



          <TriggerButton type="button" onClick={handleCloseReset}>
            OK
          </TriggerButton>
        </ModalContent>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'base-Backdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const blue = {
  200: '#99CCFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0066CC',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `,
);

const TriggerButton = styled('button')({
  fontFamily: 'IBM Plex Sans, sans-serif',
  fontWeight: 600,
  width: 100,
  fontSize: '0.875rem',
  lineHeight: 1.5,
  padding: '8px 16px',
  transition: 'all 150ms ease',
  cursor: 'pointer',
  background: '#3380FF',
  border: '1px solid #3380FF',
  color: 'white',

  '&:hover': {
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.35)',
  }
});
