import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Contracts from "../../resources/Contracts";
import { Service } from "../../services/Service";
import { actionCreators as AlertStoreActionCreators } from "../../store/AlertStore";
import { handleErrorMessage } from "../../utils/utils";
import OpensourceTable from "./opensourceTable";
import "./opensourceListContainer.scss";
//import AddDocumentSection from "./opensourceAddDocument";
import Texts from "../../resources/Texts";

type OpensourceContainerProps = typeof AlertStoreActionCreators & {};

const OpensourceContainer: React.FunctionComponent<OpensourceContainerProps> = ({
	addErrorAlert,
}) => {
	const [loading, setLoading] = React.useState<boolean>(false);
	const [opensourceDocuments, setOpensourceDocuments] = React.useState<Contracts.IOpensourceFile[]>([]);

	const loadDocumentList = React.useCallback(async (): Promise<void> => {
		setLoading(true);

		try {
			const responseItems: Contracts.IOpensourceFile[] = await Service.getOpensourceFiles();

			setOpensourceDocuments(responseItems);
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}

		setLoading(false);
	}, [addErrorAlert]);

	React.useEffect(() => {
		loadDocumentList();
	}, [loadDocumentList]);

	//const handleAddNewCategory = async (category: Contracts.IOpensourceFile): Promise<void> => {
	//	try {
	//		await Service.addNewCategory(category);

	//		loadDocumentList();
	//	} catch (error) {
	//		addErrorAlert(handleErrorMessage(error));
	//	}
	//};

	//const handleDeleteCategory = async (id: number): Promise<void> => {
		//try {
		//	await Service.deleteCategory(id);

		//	loadCategories();
		//} catch (error) {
		//	addErrorAlert(handleErrorMessage(error));
		//}
	//};

	//const handleUpdateCategory = async (value: Contracts.IOpensourceFile): Promise<void> => {
		//try {
		//	await Service.updateCategory(value);

		//	loadCategories();
		//} catch (error) {
		//	addErrorAlert(handleErrorMessage(error));
		//}
	//};

	return (
		<Box>
			<Typography
				variant="h5"
				component="div"
				className="homeView-solutionsLinks-title"
				fontFamily="Poppins"
			>
				{Texts.OpensourceView.Title}
			</Typography>
      <p>{Texts.OpensourceView.Disclaimer}</p>
              <p>Please contact <a href={Texts.OpensourceView.ContactMailTo}>{Texts.OpensourceView.ContactLinkText}</a> to obtain source code.</p>
              <p></p>
			{loading ? (
				<Box
					className="opensource-form-container"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<CircularProgress />
				</Box>
			) : (
				<Box
					className="opensource-form-container"
					display="flex"
					flexDirection="column"
				>
        
					<OpensourceTable
						osDoc={opensourceDocuments}
					/>
				</Box>
			)}
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...AlertStoreActionCreators,
		},
		dispatch
	);
};

export default connect(null, mapDispatchToProps)(OpensourceContainer);
