import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Service } from "../../services/Service";


interface RouteParams {
    nodeId: string;
}

const GetFile: React.FC = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(decodeURIComponent(location.search));
    const file = searchParams.get('file');
    const solution = searchParams.get('solution');

    const { nodeId } = useParams<RouteParams>();
    const [error, setError] = useState<number | null>(null); // State to track errors
    const msgStyle = {marginTop: '160px', marginLeft: '32px'};
    const errorText = {
        403: `The file, ${solution} - ${file}, is no longer public - please contact support.`,
        404: `The file, ${solution} - ${file}, is no longer available - please contact support.`
    };
    useEffect(() => {
        const redirectToFile = async (id: string) => {
            try {
                window.location.href = await Service.getPublicDownloadUrl(id);
            } catch (error) {
                console.error("Failed to get the download URL:", error);
                setError(error.response.status);
            }
        };

        if (nodeId) {
            redirectToFile(nodeId);
        }
    }, [nodeId]);

    if (error) {
        const errorMsg = errorText[error] ?? `Something went wrong with the file, ${solution} - ${file}, please try again. If this issue persists then please contact support.`;

            return (
                <div style={msgStyle}>
                    <h1>Error</h1>
                    <h3>{errorMsg}</h3>
                </div>
            );
    }

    return (
        <div style={msgStyle}>
            <h1>Getting file {solution} - {file} ...</h1>
        </div>
    );};

export default GetFile;
