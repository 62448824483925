import { Box } from "@mui/system";
import * as React from "react";
import { Link } from "react-router-dom";
import { Routing } from "../../resources/Routes";
import Texts from "../../resources/Texts";
import "./UserToolsButton.scss";

type UserToolsButtonProps = {};

const UserToolsButton: React.FC<UserToolsButtonProps> = () => {
	return (
		<Box
			className="header-link-box header-UserTools"
			display="flex"
			alignItems="center"
			position="relative"
			width="200px"
		>
			{Texts.Navigation.UserTools}

			<Box
				className="UserTools-menu"
				display="flex"
				flexDirection="column"
				alignItems="centre"
				position="absolute"
				width="240px"
            >
				<Link className="header-link link" to={Routing.getMyChannelsViewUrl()}>
					{Texts.Navigation.MyChannels}
				</Link>
				
			</Box>
		</Box>
	);
};

export default UserToolsButton;
