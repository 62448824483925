import {
	Autocomplete,
	AutocompleteRenderInputParams,
	AutocompleteRenderOptionState,
	Checkbox,
	TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Texts from "../../../resources/Texts";
import { ICompany, IRole } from "../../../resources/Contracts";
import { ApplicationState } from "../../../store";
import * as FileManagementStore from "../../../store/FileManagement";
import * as AlertStore from "../../../store/AlertStore";
import "./CompanySelectColumn.scss";
import { Service } from "../../../services/Service";
import { handleErrorMessage } from "../../../utils/utils";

type CompanySelectColumnProps = FileManagementStore.FileManagementState &
	typeof FileManagementStore.actionCreators &
	typeof AlertStore.actionCreators & {};

const CompanySelectColumn: React.FunctionComponent<CompanySelectColumnProps> = (props) => {
	const [companies, setCompanies] = React.useState<ICompany[]>([]);
	const [companiesSearchValue, setCompaniesSearchValue] = React.useState<string>("");
	const [roles, setRoles] = React.useState<IRole[]>([]);

	let companySearchTimeout: NodeJS.Timeout;

	React.useEffect(() => {
		let isMounted: boolean = true;

		const loadRoles = async (): Promise<void> => {
			try {
				const roles: IRole[] = await Service.getRoles();

				if (isMounted) {
					setRoles(roles);
				}
			} catch (error) {
				props.addErrorAlert(handleErrorMessage(error));
			}
		};

		loadRoles();

		return () => {
			isMounted = false;
		};
	}, []);

	const loadCompanies = async (searchTerm?: string): Promise<void> => {
		if (searchTerm) {
			try {
				const companies: ICompany[] = await Service.getCompanies(searchTerm);

				setCompanies(companies);
			} catch (error) {
				props.addErrorAlert(handleErrorMessage(error));
			}
		} else {
			setCompanies([]);
		}
	};

	const handleCompanySearchChange = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	): void => {
		const value: string = event.target.value;

		setCompaniesSearchValue(value);
		clearTimeout(companySearchTimeout);

		if (value) {
			companySearchTimeout = setTimeout(() => {
				loadCompanies(event.target.value);
			}, 500);
		} else {
			setCompanies([]);
		}
	};

	const handleCompanySearchBlur = (): void => {
		setCompaniesSearchValue("");
		setCompanies([]);
	};

	const handleCompanyValueChange = (
		event: React.SyntheticEvent<Element, Event>,
		value: (string | ICompany)[]
	) => {
		props.setFileManagementFile({
			...props.fileData,
			permittedCompanies: value as ICompany[],
		});
	};

	const handleRoleValueChange = (
		event: React.SyntheticEvent<Element, Event>,
		value: (string | IRole)[]
	) => {
		props.setFileManagementFile({
			...props.fileData,
			permittedRoles: value as IRole[],
		});
	};

	return (
		<Box
			className="fileManagement-audience-column selectCompany-column"
			display="flex"
			flexDirection="column"
			flex={[0, 2]}
		>
			<Box className="column-title" display="flex" flex={[1, 0]}>
				{Texts.FileManagementView.Sections.Audience.SelectCompanyColumn.Title}
			</Box>

			<span className="permission-guide">
				{Texts.FileManagementView.Sections.Audience.SelectCompanyColumn.GuideText1}
			</span>
			<span className="permission-guide">
				{Texts.FileManagementView.Sections.Audience.SelectCompanyColumn.GuideText2}
			</span>

			<Autocomplete
				disablePortal
				className="formField"
				options={companies || []}
				value={props.fileData?.permittedCompanies || []}
				inputValue={companiesSearchValue}
				renderInput={(params: AutocompleteRenderInputParams) => (
					<TextField
						{...params}
						onChange={handleCompanySearchChange}
						onBlur={handleCompanySearchBlur}
						label="Search for a company"
					/>
				)}
				renderOption={(
					optionProps: React.HtmlHTMLAttributes<HTMLLIElement>,
					option: ICompany,
					state: AutocompleteRenderOptionState
				) => (
					<Box
						display="flex"
						flexDirection="row"
						alignItems="center"
						component="li"
						{...optionProps}
					>
						<Checkbox checked={state.selected} />
						{option.companyName}
					</Box>
				)}
				getOptionLabel={(option: ICompany) => option.companyName}
				disableCloseOnSelect
				onChange={handleCompanyValueChange}
				multiple
			/>

			<Autocomplete
				disablePortal
				className="formField"
				options={roles || []}
				value={props.fileData?.permittedRoles || []}
				renderInput={(params: AutocompleteRenderInputParams) => <TextField {...params} label="Choose a role" />}
				getOptionLabel={(option: IRole) => option.roleName}
				disableCloseOnSelect
				onChange={handleRoleValueChange}
				multiple
			/>
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...FileManagementStore.actionCreators,
			...AlertStore.actionCreators,
		},
		dispatch
	);
};

export default connect(
	(state: ApplicationState) => state.fileManagement,
	mapDispatchToProps
)(CompanySelectColumn);
