import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { Box, CircularProgress } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ISolutionTab } from "../../resources/Contracts";
import Texts from "../../resources/Texts";
import { Service } from "../../services/Service";
import { ApplicationState } from "../../store";
import { actionCreators as AlertStoreActionCreators } from "../../store/AlertStore";
import * as SelectedSolutionStore from "../../store/SelectedSolution";
import { handleErrorMessage } from "../../utils/utils";
import RoleRestrictedComponent from "../shared/formControls/RoleRestrictedComponent";
import NewSolutionsTabsInput from "./NewSolutionsTabsInput";
import "./SolutionsTabs.scss";

type SolutionsTabsProps = typeof SelectedSolutionStore.actionCreators &
	typeof AlertStoreActionCreators &
	SelectedSolutionStore.SelectedSolutionState & {
		solutionId: number;
		solutionGroup: string;
		defaultSelectedTabId: string;
	};

const SolutionsTabs: React.FC<SolutionsTabsProps> = ({
	solutionId,
	solutionGroup,
	setSelectedSolution,
	defaultSelectedTabId,
	selectedSolutionsTabId,
	setSelectedSolutionTabId,
	addErrorAlert,
}) => {
	const [parentItem, setParentItem] = React.useState<ISolutionTab>(null);
	const [tabs, setTabs] = React.useState<ISolutionTab[]>([]);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [newTabInputVisible, setnewTabInputVisible] = React.useState<boolean>(false);

	const loadTabs = React.useCallback(
		async (setFirstAsSelected: boolean): Promise<void> => {
			setLoading(true);

			try {
				if (solutionId) {
					const responseItems: ISolutionTab[] = await Service.getTabsByCategoryId(
						solutionId
					);

					setTabs(responseItems.slice(1));
					setParentItem(responseItems[0]);

					if (responseItems?.[1] && setFirstAsSelected) {
						setSelectedSolutionTabId(responseItems[1].id);
					}
				}
			} catch (error) {
				addErrorAlert(handleErrorMessage(error));
			}

			setLoading(false);
		},
		[solutionGroup, solutionId, setSelectedSolutionTabId, addErrorAlert]
	);

	React.useEffect(() => {
		if (defaultSelectedTabId) {
			setSelectedSolutionTabId(Number(defaultSelectedTabId).toString());
		}

		loadTabs(!defaultSelectedTabId);
	}, [loadTabs, defaultSelectedTabId, setSelectedSolutionTabId]);

	const addNewTab = async (tabName: string): Promise<void> => {
		setLoading(true);

		try {
			await Service.addNewFolder(parentItem.id, tabName);

			loadTabs(false);
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}

		setLoading(false);
	};

	const handleTabSelect = (event: React.SyntheticEvent, newValue: string): void => {
		setSelectedSolutionTabId(newValue);
		setSelectedSolution(null);
	};

	const setInputVisible = (): void => {
		setnewTabInputVisible(true);
	};

	const setInputHidden = (): void => {
		setnewTabInputVisible(false);
	};

	const handleAddNewTab = (tabName: string): void => {
		addNewTab(tabName);

		setnewTabInputVisible(false);
	};

	return loading ? (
		<CircularProgress />
	) : (
		<Box display="flex" flexDirection="column" flex={1} className="solutionsTabs-container">
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				flex={1}
				className="solutionsTabs-wrapper"
			>
				<Tabs
					value={Number(selectedSolutionsTabId)}
					onChange={handleTabSelect}
					className="solutionsTabs"
					TabIndicatorProps={{
						hidden: true,
					}}
				>
					{tabs.map((tab) => (
						<Tab
							label={tab.navigationName}
							value={tab.id}
							className="tab"
							key={tab.id}
						/>
					))}
				</Tabs>

				<RoleRestrictedComponent enabledRoles={["Engineer"]}>
					<Box
						className="solutionsTabs-addNewCategory"
						onClick={setInputVisible}
						display="flex"
						flexDirection="row"
						fontSize=".75em"
						alignItems="center"
					>
						<AccountTreeIcon className="icon" />
						{Texts.SolutionsView.Tabs.AddNewCategory}
					</Box>
				</RoleRestrictedComponent>
			</Box>

			<RoleRestrictedComponent enabledRoles={["Engineer"]}>
				<NewSolutionsTabsInput
					visible={newTabInputVisible}
					onAdd={handleAddNewTab}
					onCancel={setInputHidden}
				/>
			</RoleRestrictedComponent>
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...SelectedSolutionStore.actionCreators,
			...AlertStoreActionCreators,
		},
		dispatch
	);
};

export default connect(
	(state: ApplicationState) => state.selectedSolutions,
	mapDispatchToProps
)(SolutionsTabs);
