import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { SolutionsGroup } from "../../resources/Enums";
import { Routing } from "../../resources/Routes";
import Texts from "../../resources/Texts";
import "./ServicesMenu.scss";
import { ApplicationState } from "../../store";
import * as SelectedSolutionStore from "../../store/SelectedSolution";
import { redirectToSupport } from "../../utils/utils";
import LoggedInSynamediaRestrictedComponent from "../shared/formControls/LoggedInSynamediaRestrictedComponent";

type ServicesMenuProps = SelectedSolutionStore.SelectedSolutionState &
	typeof SelectedSolutionStore.actionCreators & {};

const ServicesMenu: React.FC<ServicesMenuProps> = ({
	selectedSolution,
	selectedSolutionsTabId,
	setSelectedSolution,
	setSelectedSolutionTabId,
}) => {
	const handleLinkClick = (): void => {
		if (selectedSolution) {
			setSelectedSolution(null);
		}

		if (selectedSolutionsTabId) {
			setSelectedSolutionTabId(null);
		}
	};

	return (
		<Box
			className="header-link-box header-services"
			display="flex"
			alignItems="center"
			position="relative"
		>
			{Texts.Navigation.Services}

			<Box
				className="services-menu"
				display="flex"
				flexDirection="column"
				position="absolute"
			>
				<Box className="header-link link" onClick={redirectToSupport}>
					{Texts.HomeView.ServicesLinks.SupportTicketsLink}
				</Box>

				<Link
					className="header-link link"
					to={Routing.getSolutionsViewUrl({
						group: SolutionsGroup.EOL,
					})}
					onClick={handleLinkClick}
				>
					{Texts.HomeView.ServicesLinks.EoLNoticesLink}
				</Link>

				<Link
					className="header-link link"
					to={Routing.getSolutionsViewUrl({
						group: SolutionsGroup.FieldNotices,
					})}
					onClick={handleLinkClick}
				>
					{Texts.HomeView.ServicesLinks.FieldNoticesLink}
				</Link>

				<Link
					className="header-link link"
					to={Routing.getContactsViewUrl()}
					onClick={handleLinkClick}
				>
					{Texts.HomeView.ServicesLinks.ContactsLink}
				</Link>

				<Link
					className="header-link link"
					to={Routing.getOpensourceViewUrl()}
					onClick={handleLinkClick}
				>
					{Texts.HomeView.ServicesLinks.OpenSourceLink}
				</Link>

				<LoggedInSynamediaRestrictedComponent>
					<Link
						className="header-link link"
						to={Routing.getHelpViewUrl()}
						onClick={handleLinkClick}
					>
					{Texts.HomeView.ServicesLinks.HelpLink}
					</Link>
				</LoggedInSynamediaRestrictedComponent>
			</Box>
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...SelectedSolutionStore.actionCreators,
		},
		dispatch
	);
};

export default connect(
	(state: ApplicationState) => state.selectedSolutions,
	mapDispatchToProps
)(ServicesMenu);
