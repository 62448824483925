import { Box } from "@mui/system";
import * as React from "react";
import Texts from "../../../resources/Texts";
import * as FileManagementStore from "../../../store/FileManagement";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./FileTypeColumn.scss";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { FileType } from "../../../resources/Enums";

type FileTypeColumnProps = FileManagementStore.FileManagementState &
	typeof FileManagementStore.actionCreators & {};

const FileTypeColumn: React.FunctionComponent<FileTypeColumnProps> = (props) => {
	const handleFileTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const fileTypeValue: FileType = event.target.value as FileType;

		props.setFileManagementFile({
			...props.fileData,
			metadata: {
				...props.fileData?.metadata,
				fileType: fileTypeValue,
			},
		});
	};

	return (
		<Box
			className="fileManagement-metadata-column fileType-column"
			display="flex"
			flexDirection="column"
			flex={[0, 1]}
		>
			<Box className="column-title" display="flex" flex={[1, 0]}>
				{Texts.FileManagementView.Sections.Metadata.TypeColumn.Title}
			</Box>

			<RadioGroup value={props.fileData?.metadata?.fileType} onChange={handleFileTypeChange}>
				<FormControlLabel
					className="fileType-radio"
					value={FileType.Software}
					control={<Radio />}
					label={FileType.Software}
				/>

				<FormControlLabel
					className="fileType-radio"
					value={FileType.Documentation}
					control={<Radio />}
					label={FileType.Documentation}
				/>

				<FormControlLabel
					className="fileType-radio"
					value={FileType.Training}
					control={<Radio />}
					label={FileType.Training}
				/>
			</RadioGroup>
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...FileManagementStore.actionCreators,
		},
		dispatch
	);
};

export default connect(
	(state: ApplicationState) => state.fileManagement,
	mapDispatchToProps
)(FileTypeColumn);
