import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import Texts from "../../resources/Texts";
import { ApplicationState } from "../../store";
import { CurrentUserState } from "../../store/CurrentUser";
import "./LoginButton.scss";
import { Menu, MenuItem } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type LoginButtonProps = CurrentUserState & {};

const LoginButton: React.FC<LoginButtonProps> = (props) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleLogout = (): void => {
		window.location.href = "/api/auth/logout";
	};

	const handleMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleInternalLogin = () => {
		window.location.href = "/api/homead";
	};

	const handleExternalLogin = () => {
		window.location.href = "/api/homeb2c";
	};

	return (
		<Box className="login-button-wrapper" display="flex" alignItems="center">
			{props.user ? (
				<>
					<Box
						className="login-button"
						display="flex"
						alignItems="center"
						onClick={handleMenuOpen}
					>
						{props.user.username}
						<ExpandMoreIcon
							className="icon"
							width="20px"
							height="20px"
							sx={{ mt: "4px", ml: "8px" }}
						/>
					</Box>
					<Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
						<MenuItem onClick={handleLogout}>{Texts.Navigation.Logout}</MenuItem>
					</Menu>
				</>
			) : (
				<>
					<Box
						className="login-button"
						display="flex"
						alignItems="center"
						onClick={handleMenuOpen}
					>
						{Texts.Navigation.Login}
						<ExpandMoreIcon
							className="icon"
							width="20px"
							height="20px"
							sx={{ mt: "4px", ml: "8px" }}
						/>
					</Box>
					<Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
						<MenuItem onClick={handleInternalLogin}>
							{Texts.Navigation.LoginInternalUser}
						</MenuItem>
						<MenuItem onClick={handleExternalLogin}>
							{Texts.Navigation.LoginExternalUser}
						</MenuItem>
					</Menu>
				</>
			)}
		</Box>
	);
};

export default connect((state: ApplicationState) => state.currentUser)(LoginButton);
