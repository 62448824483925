import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import "./HelpView.scss";
import ViewHeader from "../../components/shared/ViewHeader";
import downloadLink from "./downloadlink.png";
import downloadDialog from "./downloadDialog.png"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import overwriteFile from "./overwriteFile.png"
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Texts from "../../resources/Texts";

interface OpensourceViewProps {}

const HelpView: React.FunctionComponent<OpensourceViewProps> = () => {
	return (
		<Box className="help-view" display="flex" flexDirection="column">
			<Container>
				<ViewHeader title={Texts.SolutionsView.ViewTitle} />
				<Box
					className="solutionsView-groupTitle-box"
					display="flex"
					flexDirection="row"
					alignItems="center"
				>
					<HelpOutlineIcon className={"icon"}/>

					<Typography
						variant="h4"
						component="div"
						className="solutionsView-groupTitle"
						fontFamily="Poppins"
					>
						Help
					</Typography>
				</Box>
				<div style={{display: 'flex', alignItems: 'center', minHeight: '64px'}}>
					<div style={{marginLeft: '8px', paddingBottom: '8px'}}>
						<h2>Software Download APIs</h2>
						<p>The Software Download Portal now supports <i>Software Download APIs</i> that can
							be integrated with your product
							so that you can automatically download new updates etc. without the customer
							having to manually download updates.
						</p>
						<h2>Download Link</h2>
						<p>For software/documentation which have been marked as <b>public</b>, you can now
							get a direct download link that can be shared.
							For example, if you have a PDF user guide for a product, you can get a download
							URL and convert it to a QR
							code to be shared with your customers.</p>
						<img src={downloadLink} alt="" style={{width: '600px', height: 'auto'}}/>
						<p>If the item is <b>public</b> then a new download link will be displayed as shown
							above.
							Click on that in order to get the download URL.</p>
						<img src={downloadDialog} alt="" style={{width: '700px', height: 'auto'}}/>
						<p>You can then click on <ContentCopyIcon/> to copy the URL to the clipboard.</p>
						<p style={{marginTop: '32px'}}><i>For further details please contact Neil Thomas
							(neithoma@synamedia.com)</i></p>
						<h2>Overwrite A File</h2>
						<p>If you've shared a download link e.g. as a QR code to a user guide, and you want
							to update
							that file without generating a new URL, you can overwrite the
							file. <b>Note: </b>
							The old version of the file will no longer be accessible so you must
							download/re-upload as
							a new file first if you wish to retain it. Click on <UploadFileIcon/> to upload the new
							version.
						</p>
						<img src={overwriteFile} alt="" style={{width: '700px', height: 'auto'}}/>
					</div>
				</div>
			</Container>
		</Box>
	);
};

export default HelpView;
