import {
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { IFileDownloadData } from "../../resources/Contracts";
import Texts from "../../resources/Texts";
import { Service } from "../../services/Service";

type DownloadDialogProps = {
	open: boolean;
	close: (value: boolean) => void;
};

const DownloadDialog: React.FunctionComponent<DownloadDialogProps> = (props) => {
	const [links, setLinks] = React.useState<IFileDownloadData[]>([]);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [selectedLinks, setSelectedLinks] = React.useState<IFileDownloadData[]>([]);

	React.useEffect(() => {
		const loadData = async (): Promise<void> => {
			setLoading(true);

			try {
				const result: IFileDownloadData[] = await Service.getDownloadCartContent();

				setLinks(result);
			} catch (error) {
				console.error(error);
			}

			setLoading(false);
		};

		loadData();
	}, []);

	const handleDownloadSelected = (): void => {
		downloadFiles(selectedLinks);
	};

	const handleDownloadAll = (): void => {
		downloadFiles(links);
	};

	const downloadFiles = (linksToBeDownloaded: IFileDownloadData[]): void => {
		linksToBeDownloaded.forEach((link) => {
			const aElement: HTMLAnchorElement = document.createElement("a");
			aElement.href = link.fileLink;
			aElement.download = link.fileName;

			document.body.appendChild(aElement);

			aElement.click();
			aElement.remove();
		});
	};

	const handleClose = (): void => {
		props.close(false);
	};

	const handleSelectAllChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		value: boolean
	): void => {
		if (value) {
			setSelectedLinks(links);
		} else {
			setSelectedLinks([]);
		}
	};

	const handleSelectChange = (link: IFileDownloadData, value: boolean): void => {
		if (value) {
			const selectedLinksCopy: IFileDownloadData[] = selectedLinks.slice();
			selectedLinksCopy.push(link);

			setSelectedLinks(selectedLinksCopy);
		} else {
			const selectedLinksCopy: IFileDownloadData[] = selectedLinks
				.slice()
				.filter((sl) => sl.fileLink !== link.fileLink);

			setSelectedLinks(selectedLinksCopy);
		}
	};

	return (
		<Dialog maxWidth="lg" open={props.open} onClose={handleClose}>
			<DialogTitle>{Texts.Dialogs.Download.Title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{Texts.Dialogs.Download.ContextText}</DialogContentText>

				<Box sx={{ width: "100%" }}>
					<Table size="small" stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell>
									<Typography variant="h6" fontFamily="Poppins">
										{Texts.Dialogs.Download.FileNameColumn}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="h6" fontFamily="Poppins">
										{Texts.Dialogs.Download.FileLinkColumn}
									</Typography>
								</TableCell>
								<TableCell>
									<Checkbox
										checked={
											links.length > 0 &&
											links.length === selectedLinks.length
										}
										onChange={handleSelectAllChange}
									/>
								</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{loading ? (
								<TableRow>
									<TableCell sx={{ padding: 0 }} colSpan={2}>
										<Box display="flex" className="usersTable-loadingIndicator">
											<CircularProgress />
										</Box>
									</TableCell>
								</TableRow>
							) : (
								links.map((link) => (
									<TableRow>
										<TableCell>{link.fileName}</TableCell>
										<TableCell>{link.fileLink}</TableCell>
										<TableCell>
											<Checkbox
												checked={selectedLinks.some(
													(sl) => sl.fileLink === link.fileLink
												)}
												onChange={(event, checked) =>
													handleSelectChange(link, checked)
												}
											/>
										</TableCell>
									</TableRow>
								))
							)}
						</TableBody>
					</Table>
				</Box>
			</DialogContent>

			<DialogActions>
				<Button onClick={handleClose}>{Texts.Buttons.Cancel}</Button>
				<Button onClick={handleDownloadSelected}>{Texts.Buttons.DownloadSelected}</Button>
				<Button onClick={handleDownloadAll}>{Texts.Buttons.DownloadAll}</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DownloadDialog;
