import * as React from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from "@mui/material";
import { styled } from '@mui/system';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionCreators as AlertStoreActionCreators } from "../../store/AlertStore";

type GetDownloadUrlDialogProps = typeof AlertStoreActionCreators & {
    open: boolean;
    handleClose: () => void;
    downloadUrl: string;
};

const GetDownloadUrlDialog: React.FC<GetDownloadUrlDialogProps> = (props) => {

    const handleCopy = async () => {
        await navigator.clipboard.writeText(props.downloadUrl);
        props.addAlert({text: 'Download URL copied to clipboard', type: 'success'});
        props.handleClose();
    };

    return (
        <Dialog
            open={props.open}
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogContent>
                <div style={{textAlign: "center", fontWeight: 'bold', fontSize: '24px'}}>Download URL</div>
                <div style={{display: 'flex', flexDirection: 'column', gap: '24px'}}>
                    <div style={{display: 'flex', alignItems: 'center', minHeight: '64px'}}>
                        <IconButton component="span" sx={{marginBottom: '8px'}} onClick={handleCopy}>
                            <ContentCopyIcon/>
                        </IconButton>
                        <div style={{whiteSpace: 'nowrap', overflowX: 'auto', marginLeft: '8px', paddingBottom: '8px'}}>
                            {props.downloadUrl}
                        </div>
                    </div>
                    <TriggerButton onClick={props.handleClose}>Ok</TriggerButton>
                </div>
            </DialogContent>
        </Dialog>
    );
}

  const TriggerButton = styled('button')({
	  fontFamily: 'IBM Plex Sans, sans-serif',
	  fontWeight: 600,
	  maxWidth: '80px',
	  margin: 'auto',
	  fontSize: '0.875rem',
	  lineHeight: 1.5,
	  padding: '8px 16px',
	  transition: 'all 150ms ease',
	  cursor: 'pointer',
	  background: '#3380FF',
	  border: '1px solid #3380FF',
	  color: 'white',

	  '&:hover': {
		  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.35)',
    }
  });

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...AlertStoreActionCreators,
		},
		dispatch
	);
};

export default connect(null, mapDispatchToProps)(GetDownloadUrlDialog);
