import { Action, Reducer } from "redux";
import { ISolutionTreeItem } from "../resources/Contracts";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface SelectedSolutionState {
	selectedGroup: string;
	selectedGroupId: number;
	selectedSolution: ISolutionTreeItem | null;
	selectedSolutionsTabId: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface SetGroupAction {
	type: "SET_SELECTED_GROUP";
	selectedGroup: string;
}

export interface SetGroupIdAction {
	type: "SET_SELECTED_GROUP_ID";
	selectedGroupId: number;
}

export interface SetSolutionAction {
	type: "SET_SELECTED_SOLUTION";
	selectedSolution: ISolutionTreeItem;
}

export interface SetSolutionTabAction {
	type: "SET_SELECTED_SOLUTION_TAB";
	selectedTabId: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetGroupIdAction |SetSolutionAction | SetSolutionTabAction | SetGroupAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
	setSelectedGroup: (group: string | null) =>
		({ type: "SET_SELECTED_GROUP", selectedGroup: group } as SetGroupAction),
	setSelectedGroupId: (groupId: number | null) =>
		({ type: "SET_SELECTED_GROUP_ID", selectedGroupId: groupId } as SetGroupIdAction),
	setSelectedSolution: (solution: ISolutionTreeItem | null) =>
		({ type: "SET_SELECTED_SOLUTION", selectedSolution: solution } as SetSolutionAction),
	setSelectedSolutionTabId: (tab: string | null) =>
		({ type: "SET_SELECTED_SOLUTION_TAB", selectedTabId: tab } as SetSolutionTabAction)
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<SelectedSolutionState> = (
	state: SelectedSolutionState | undefined,
	incomingAction: Action
): SelectedSolutionState => {
	if (state === undefined) {
		return {
			selectedGroup: null,
			selectedGroupId: null,
			selectedSolution: null,
			selectedSolutionsTabId: null
		};
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case "SET_SELECTED_GROUP":
			return { ...state, selectedGroup: action.selectedGroup };

		case "SET_SELECTED_GROUP_ID":
			return { ...state, selectedGroupId: action.selectedGroupId };

		case "SET_SELECTED_SOLUTION":
			return { ...state, selectedSolution: action.selectedSolution };

		case "SET_SELECTED_SOLUTION_TAB":
			return { ...state, selectedSolutionsTabId: action.selectedTabId };

		default:
			return state;
	}
};
