import { Button, TableCell, TableRow } from "@mui/material";
import * as React from "react";
import * as Contracts from "../../resources/Contracts";
import { Service } from "../../services/Service";


type OpensourceTableProps = {
	osDoc: Contracts.IOpensourceFile;
};

const OpensourceTableRow: React.FunctionComponent<OpensourceTableProps> = ({
	osDoc,
}) => {
	const [value, setValue] = React.useState<Contracts.IOpensourceFile>(osDoc);

	React.useEffect(() => {
		setValue(osDoc);
	}, [osDoc]);
  	
  const handleDocView = async (Filename: string) => {
		try {
			const fileDownloadData: Contracts.IDocumentLink = await Service.getDocumentLink(Filename);

			const aElement: HTMLAnchorElement = document.createElement("a");
			aElement.href = fileDownloadData.url;
			aElement.download = fileDownloadData.filename;
      console.log(fileDownloadData);
      console.log(aElement);
			document.body.appendChild(aElement);

			aElement.click();
			aElement.remove();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<TableRow>
    <TableCell width="150px">
				<b>{value.ProductName || <>&nbsp;</>}</b>
			</TableCell>
      <TableCell width="100px">
				<b>{value.ProductRelease || <>&nbsp;</>}</b>
			</TableCell>
      <TableCell width="300px">
				<b>{value.Title || <>&nbsp;</>}</b>
			</TableCell>

			<TableCell width="150px">
				{value.Description}
			</TableCell>
      <TableCell width="150px">
				{value.ProductReleaseDate}
			</TableCell>
      <TableCell width="50px">
        <Button
					variant="contained"
					component="label"
					className="button button-select"
					onClick={() => handleDocView(value.Filename)}
				>
					VIEW
				</Button>
			</TableCell>
		</TableRow>
	);
};

export default OpensourceTableRow;
