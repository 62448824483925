import { Container } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import "./OpensourceView.scss";
import OpensourceListContainer from "../../components/opensourceList/opensourceListContainer";

interface OpensourceViewProps {}

const OpensourceView: React.FunctionComponent<OpensourceViewProps> = (props) => {
	return (
		<Box className="opensource-view" display="flex" flexDirection="column">
			<Container>
				<OpensourceListContainer />
			</Container>
		</Box>
	);
};

export default OpensourceView;
