import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FilePermission } from "../../../resources/Enums";
import Texts from "../../../resources/Texts";
import { ApplicationState } from "../../../store";
import ConfirmPublicPermissionDialog from "../../dialogs/ConfirmPublicPermissionDialog";
import * as FileManagementStore from "../../../store/FileManagement";
import "./SecurityPermissionsColumn.scss";

type SecurityPermissionsColumnProps = FileManagementStore.FileManagementState &
	typeof FileManagementStore.actionCreators & {};

const SecurityPermissionsColumn: React.FunctionComponent<SecurityPermissionsColumnProps> = (
	props
) => {
	const [confirmPublicDialogOpen, setDialogOpen] = React.useState<boolean>(false);

	const handlePermissionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const filePermissionValue: FilePermission = event.target.value as FilePermission;

		if (filePermissionValue === FilePermission.Public) {
			setDialogOpen(true);
		} else {
			props.setFileManagementFile({
				...props.fileData,
				permission: filePermissionValue,
			});
		}
	};

	const handleConfirmPublic = (): void => {
		props.setFileManagementFile({
			...props.fileData,
			permission: FilePermission.Public,
		});
	};

	return (
		<Box
			className="fileManagement-audience-column permission-column"
			display="flex"
			flexDirection="column"
			flex={[0, 1]}
		>
			<Box className="column-title" display="flex" flex={[1, 0]}>
				{Texts.FileManagementView.Sections.Audience.PermissionsColumn.Title}
			</Box>

			<RadioGroup value={props.fileData?.permission || ""} onChange={handlePermissionChange}>
				<FormControlLabel
					className="filePermission-radio"
					value={FilePermission.Registered}
					control={<Radio />}
					label={
						Texts.FileManagementView.Sections.Audience.PermissionsColumn.Options
							.Registered
					}
				/>

				<FormControlLabel
					className="filePermission-radio"
					value={FilePermission.RegisteredAll}
					control={<Radio />}
					label={
						Texts.FileManagementView.Sections.Audience.PermissionsColumn.Options
							.RegisteredAll
					}
				/>

				<FormControlLabel
					className="filePermission-radio"
					value={FilePermission.Public}
					control={<Radio />}
					label={
						Texts.FileManagementView.Sections.Audience.PermissionsColumn.Options.Public
					}
				/>
			</RadioGroup>

			<ConfirmPublicPermissionDialog
				open={confirmPublicDialogOpen}
				close={() => setDialogOpen(false)}
				onConfirm={handleConfirmPublic}
			/>
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...FileManagementStore.actionCreators,
		},
		dispatch
	);
};

export default connect(
	(state: ApplicationState) => state.fileManagement,
	mapDispatchToProps
)(SecurityPermissionsColumn);
