import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { Box } from "@mui/system";
import * as React from "react";
import Texts from "../../../resources/Texts";
import "./FileMetadataSection.scss";
import TitleColumn from "./TitleColumn";
import VersionColumn from "./VersionColumn";
import FileTypeColumn from "./FileTypeColumn";
import FileInfoColumn from "./FileInfoColumn";

type FileMetadataSectionProps = {};

const FileMetadataSection: React.FunctionComponent<FileMetadataSectionProps> = (props) => {
	return (
		<Box
			className="fileManagement-formSection fileManagement-metadataSection"
			display="flex"
			flexDirection="column"
			flex={1}
		>
			<Box className="fileManagement-section-title" display="flex" alignItems="center">
				<LocalOfferIcon className="icon" />
				{Texts.FileManagementView.Sections.Metadata.Title}
			</Box>

			<Box className="fileManagement-metadata-controls" display="flex" flex={1}>
				<TitleColumn />

				<Box className="fileManagement-metadata-column center" display="flex" flex={[0, 1]}>
					<Box
						className="centerColumn-half"
						display="flex"
						flexDirection="column"
						flex={[0, 1]}
					>
						<VersionColumn />
					</Box>

					<Box
						className="centerColumn-half"
						display="flex"
						flexDirection="column"
						flex={[0, 1]}
					>
						<FileTypeColumn />
					</Box>
				</Box>

				<FileInfoColumn />
			</Box>
		</Box>
	);
};

export default FileMetadataSection;
