import * as React from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FileUploadSection from './fileUploadSection/FileUploadSection'
import { styled } from '@mui/system';

interface NewFileVersionUploadProps {
    uploadDialogOpen: boolean;
    dialogClosed: () => void;
    existingFileGuid: string;
}

const NewFileVersionUpload: React.FC<NewFileVersionUploadProps> = (props) => {
    const [cancelVisible, setCancelVisible] = React.useState<string>("visible");

    React.useEffect(() => {
        if (props.uploadDialogOpen) setCancelVisible("visible");
    }, [props.uploadDialogOpen]);

    const uploadStarted = () => setCancelVisible("hidden");

    return (
            <Dialog
                open={props.uploadDialogOpen}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogContent>
                    <h2 style={{ display: 'flex', alignItems: 'center' }}>
                        <UploadFileIcon sx={{fontSize: '36px', paddingRight:'16px'}}/> Upload New File Version
                    </h2>
                    <div style={{background: 'LightCoral', marginBottom: '32px', display: 'flex', alignItems: 'center'}}>
                            <p style={{padding: '16px 16px 0 16px'}}><b>WARNING</b> - You are about to upload a new version of this file. The original file will be overwritten and can not be recovered.
                            Do not refresh your browser or close this tab during upload. Wait until this dialog is dismissed.</p>
                        <TriggerButton type="button" sx={{visibility: cancelVisible}} onClick={props.dialogClosed}>
                            CANCEL
                        </TriggerButton>
                    </div>
                    <FileUploadSection
                        editFileTitle={''}
                        onUploadStarted={uploadStarted}
                        onUploadFinished={() => props.dialogClosed()}
                        existingFileGuid = {props.existingFileGuid}
                    />
                </DialogContent>
            </Dialog>
    );
};

const TriggerButton = styled('button')({
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontWeight: 600,
    maxWidth: '96px',
    maxHeight: '40px',
    marginRight: '40px',
    fontSize: '0.875rem',
    lineHeight: 1.5,
    padding: '8px 8px',
    transition: 'all 150ms ease',
    cursor: 'pointer',
    background: 'grey',
    border: '1px solid grey',
    color: 'white',

    '&:hover': {
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.35)',
    }
});

export default NewFileVersionUpload;