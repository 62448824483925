/*
  ChannelList component (tsx)
  --------------------------------------------------------------------------------
  description
  ------------------------------
    This version:  17/07/2024
*/
import * as React from 'react';
import { useEffect, useState } from 'react';

// mui
import Box from '@mui/material/Box';
import { Alert, Button, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';

// custom
import CustomTextField from '../CustomTextField';
import { IChannelCompany, IChannel, IChannelPermission } from '../../../resources/Contracts';
import CompanyPickerModal from '../CompanyPickerModal';

export default function ChannelList( { setSelectedChannel } ) 
{
  const apiUrl="/api/Channel";
  const [message, setMessage] = React.useState("");                                     // alert message contents
  const [data, setData] = useState<IChannel[]>([]);                            // data read from companyApiUrl
  //const [pickedChannel, setPickedChannel] = useState<IChannel>();           // selected channel returned to caller
  
  const columns: GridColDef<(IChannel)>[] = [
    { 
      field: 'id', 
      headerName: 'ID',
      width: 90,
      editable: false,
    },
    {
        field: 'navigationId',
        headerName: 'Navigation ID',
        sortable: false,
        //width: 20,
        editable: false,
      },
      {
        field: 'name',
        headerName: 'Name',
        headerClassName: 'table-header',
        width: 200,
        editable: false,
      },
      {
        field: 'description',
        headerName: 'Description',
        headerClassName: 'table-header',
        width: 300,
        editable: false,
      },
    {
        field: 'resolvedNavigationNode',
        headerName: 'Shared Path',
        headerClassName: 'table-header',
        sortable: false,
        flex: 1,
        editable: false,
    },
   
    {
        field: 'channelUniqueId',
        headerName: 'Channel ID',
        width:300,
        editable: false,
   },
    
    
    
    {
      field: 'navigationNode',
      headerName: 'Hierarchy ID',
      sortable: false,
      //width: 20,
      editable: false,
    },
   
    {
        field: 'isEnabled',
        headerName: 'Enabled',
        sortable: false,
        width: 10,
        editable: false,
    },
    {
        field: 'isBlocked',
        headerName: 'Blocked',
        sortable: false,
       // width: 10,
        editable: false,
    },
];

    const handleSelectionChange = (newSelectionModel: number[]) => {
        const selectedRow = data.filter((row) => newSelectionModel.includes(row.id));
        const selectedChannel = selectedRow.length !== 0 ? selectedRow[0].channelUniqueId : "";
        setSelectedChannel(selectedChannel);
    }

  useEffect(() => {
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => setData( data ))
      .catch((error) => console.error('Error fetching channel data:', error));
  }, [apiUrl]);

  return (
    <Box sx={{ height: '600px', width: '100%' }}>
      <Stack direction="row" spacing={3} >
        {message && <Alert severity="info">{message}</Alert>}

    </Stack>
    <Box sx={{ height: '580px',
        width: '100%',
        '& .table-header': {
            backgroundColor: 'powderblue',
        },
        '& .MuiDataGrid-columnHeaderCheckbox': {
            backgroundColor: 'powderblue',
        },
    }}>
          <DataGrid
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                navigationNode: false,
                navigationId: false,
                channelUniqueId: false,
                isEnabled: false,
                isBlocked: false
              },
            },
          }}
            rows={data}
            columns={columns}
            checkboxSelection={true}
            disableMultipleRowSelection={true}
            rowSelection
            onRowSelectionModelChange={handleSelectionChange}
          />
        </Box>
      <Stack direction="row" spacing={1} >
      </Stack>
    </Box>
  );
}