import * as React from "react";
import MyChannelsContainer from "../../components/myChannels/MyChannelsContainer";
import { Box } from "@mui/system";
import { Container } from "@mui/material";
import "./MyChannelsView.scss";

interface MyChannelsProps {}

const MyChannelsView: React.FunctionComponent<MyChannelsProps> = (props) => {

	return (
		<Box className="myChannels-view" display="flex" flexDirection="column">
		<Container>
			<MyChannelsContainer />
		</Container>
		</Box>
	);
};

export default MyChannelsView;
