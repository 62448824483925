export function handleErrorMessage(error: Error): string {
	if (error.message) {
		return error.message;
	} else if (error.name) {
		return error.name;
	} else {
		return JSON.stringify(error);
	}
}

export function formatFileSize(size: number): string {
	if (size === 0) {
		return "0 Bytes";
	}

	const k = 1024;

	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
	const sizeIndex = Math.floor(Math.log(size) / Math.log(k));

	return parseFloat((size / Math.pow(k, sizeIndex)).toFixed(2)) + " " + sizes[sizeIndex];
}

export function redirectToSupport(): void {
	window.open("https://vss.service-now.com/", "_blank");
}
