import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { useLocation } from "react-router-dom";
import ViewHeader from "../../components/shared/ViewHeader";
import SolutionsBrowser from "../../components/solutionsBrowser/SolutionsBrowser";
import { ReactComponent as EolNoticeIcon } from "../../images/icons/icon-eol-notice-black.svg";
import { ReactComponent as FieldNoticesIcon } from "../../images/icons/icon-field-notices-black.svg";
import { SolutionsGroup } from "../../resources/Enums";
import { SolutionsViewRouteParamaters } from "../../resources/RouteParams";
import Texts from "../../resources/Texts";
import { parseViewParameters } from "../../utils/viewUtils";
import "./SolutionsView.scss";
import { ISolutionTab } from "../../resources/Contracts";
import { Service } from "../../services/Service";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionCreators as AlertStoreActionCreators } from "../../store/AlertStore";
import { handleErrorMessage } from "../../utils/utils";

type SolutionsViewProps = typeof AlertStoreActionCreators & {};

const SolutionsView: React.FunctionComponent<SolutionsViewProps> = ({ addErrorAlert }) => {
	const viewParameters = parseViewParameters<SolutionsViewRouteParamaters>(useLocation().search);

	const [parentItem, setParentItem] = React.useState<ISolutionTab>(null);

	const loadTabs = React.useCallback(async (): Promise<void> => {
		try {
			if (viewParameters.id) {
				const responseItems: ISolutionTab[] = await Service.getTabsByCategoryId(
					viewParameters.id
				);
				setParentItem(responseItems[0]);
			}
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}
	}, [addErrorAlert, viewParameters.id]);

	React.useEffect(() => {
		loadTabs();
	}, [loadTabs]);

	let solutionsTitleText: string;
	let solutionsTitleIcon: JSX.Element;
	let showTabs: boolean;

	switch (viewParameters.group) {
		case SolutionsGroup.EOL:
			solutionsTitleText = Texts.SolutionGroups.EOL;
			solutionsTitleIcon = <EolNoticeIcon className="icon" width={70} height={70} />;
			showTabs = false;
			break;

		case SolutionsGroup.FieldNotices:
			solutionsTitleText = Texts.SolutionGroups.FieldNotices;
			solutionsTitleIcon = <FieldNoticesIcon className="icon" width={70} height={70} />;
			showTabs = false;
			break;

		default:
			solutionsTitleText = parentItem?.navigationName;

			if (parentItem?.icon) {
				let icon: string;

				if (parentItem.icon.startsWith("data:")) {
					const splitIconValue = parentItem.icon.split(",");

					icon = splitIconValue[splitIconValue.length - 1];
				} else {
					icon = parentItem.icon;
				}

				solutionsTitleIcon = (
					<img src={`data:image/svg+xml;data:image/png;base64, ${icon}`} alt="" />
				);
			} else {
				solutionsTitleIcon = <Box height="70px"></Box>;
			}

			showTabs = true;
	}

	return (
		<Box className="solutionsView" display="flex" flexDirection="column">
			<ViewHeader title={Texts.SolutionsView.ViewTitle} />

			<Container>
				<Box display="flex" flexDirection="column" className="solutionsView-container">
					<Box
						className="solutionsView-groupTitle-box"
						display="flex"
						flexDirection="row"
						alignItems="center"
					>
						{solutionsTitleIcon}

						<Typography
							variant="h4"
							component="div"
							className="solutionsView-groupTitle"
							fontFamily="Poppins"
						>
							{solutionsTitleText}
						</Typography>
					</Box>

					<SolutionsBrowser
						solutionGroup={viewParameters.group}
						solutionId={viewParameters.id}
						showTabs={showTabs}
						defaultSelectedId={viewParameters.selectedNodeId}
					/>
				</Box>
			</Container>
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...AlertStoreActionCreators,
		},
		dispatch
	);
};

export default connect(null, mapDispatchToProps)(SolutionsView);
