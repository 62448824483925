import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import { Box, CircularProgress } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ISolutionTreeItem } from "../../../resources/Contracts";
import Texts from "../../../resources/Texts";
import { Service } from "../../../services/Service";
import { ApplicationState } from "../../../store";
import { actionCreators as AlertStoreActionCreators } from "../../../store/AlertStore";
import * as FileManagementStore from "../../../store/FileManagement";
import { handleErrorMessage } from "../../../utils/utils";
import "./FileTaxonomyTree.scss";

type FileTaxonomyTreeProps = FileManagementStore.FileManagementState &
	typeof FileManagementStore.actionCreators &
	typeof AlertStoreActionCreators & {
		defaultSelectedId: string;
	};

const FileTaxonomyTree: React.FC<FileTaxonomyTreeProps> = ({
	defaultSelectedId,
	fileData,
	setFileManagementFile,
	addErrorAlert,
}) => {
	const dataLoaded = React.useRef<boolean>(false);

	//Tree items displayed
	const [rootItem, setRootItem] = React.useState<ISolutionTreeItem>(null);
	// Tree nodes with expanded children
	const [expandedNodes, setExpandedNodes] = React.useState<string[]>([]);
	// Tree item data loading
	const [loading, setLoading] = React.useState<boolean>(false);

	React.useEffect(() => {
		const handleDefaultExpandedNodes = (
			treeItem: ISolutionTreeItem,
			parentIds: string[]
		): void => {
			const parentIdsCopy: string[] = parentIds.slice();

			if (treeItem.id === defaultSelectedId) {
				setExpandedNodes(parentIdsCopy);
			} else if (treeItem.nodes?.length > 0) {
				parentIdsCopy.push(treeItem.id);
				treeItem.nodes.forEach((n) => handleDefaultExpandedNodes(n, parentIdsCopy));
			}
		};

		const loadItems = async (): Promise<void> => {
			setLoading(true);

			try {
				const responseItem: ISolutionTreeItem = await Service.getFoldersStructure(
					"Synamedia",
					false
				);

				handleDefaultExpandedNodes(responseItem, []);
				setRootItem(responseItem);
			} catch (error) {
				addErrorAlert(handleErrorMessage(error));
			}

			setLoading(false);
		};
		if (!dataLoaded.current) {
			loadItems();
			dataLoaded.current = true;
		}
	}, [defaultSelectedId, fileData, setFileManagementFile, addErrorAlert]);

	const handleExpandedNodes = (nodeId: string) => {
		let expandedNodesCopy: string[] = expandedNodes.slice();

		if (expandedNodesCopy.some((en) => en === nodeId)) {
			expandedNodesCopy = expandedNodesCopy.filter((n) => n !== nodeId);
		} else {
			expandedNodesCopy.push(nodeId);
		}

		setExpandedNodes(expandedNodesCopy);
	};

	const handleNodeClick = (node: ISolutionTreeItem) => {
		const lowestSelectableLevel: number = 3;

		if (node.level > lowestSelectableLevel) {
			const parentIdValue: string = node.id;

			setFileManagementFile({
				...fileData,
				parentId: parentIdValue,
			});
		}

		if (node.nodes?.length > 0) {
			handleExpandedNodes(node.id);
		}
	};

	const handleCollapseAll = (): void => {
		setExpandedNodes([]);
	};

	const renderTreeItems = (node: ISolutionTreeItem) => {
		let className: string = expandedNodes.some((sn) => sn === node.id)
			? "tree-item expanded"
			: "tree-item";

		if (node.id === fileData?.parentId) {
			className = `${className} selected`;
		}

		return (
			<TreeItem
				id={node.id}
				key={node.id}
				nodeId={node.id}
				label={node.text}
				onClick={() => handleNodeClick(node)}
				className={className}
			>
				{Array.isArray(node.nodes) && node.nodes?.length > 0
					? node.nodes.map((subNode) => renderTreeItems(subNode))
					: null}
			</TreeItem>
		);
	};

	return loading ? (
		<Box display="flex" flex={1} justifyContent="center">
			<CircularProgress />
		</Box>
	) : (
		<Box className="fileTaxonomy-tree-container" display="flex" flex={1} flexDirection="column">
			<Box
				className="fileTaxonomy-collapseAll-button-wrapper"
				display="flex"
				flexDirection="row"
				justifyContent="center"
				flex={1}
			>
				<span className="fileTaxonomy-collapseAll-button" onClick={handleCollapseAll}>
					{Texts.FileManagementView.Sections.Taxonomy.CollapseAll}
				</span>
			</Box>

			<TreeView
				className="fileTaxonomy-tree"
				defaultCollapseIcon={<ArrowDropDownIcon className="icon icon-arrow-down" />}
				defaultExpandIcon={<ArrowRightIcon className="icon icon-arrow-right" />}
				expanded={expandedNodes}
				selected={fileData?.parentId ? [fileData.parentId] : []}
			>
				{rootItem && renderTreeItems(rootItem)}
			</TreeView>
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...AlertStoreActionCreators,
			...FileManagementStore.actionCreators,
		},
		dispatch
	);
};

export default connect(
	(state: ApplicationState) => state.fileManagement,
	mapDispatchToProps
)(FileTaxonomyTree);
