import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { Link } from "react-router-dom";
import "./HomePageLink.scss";

interface HomePageLinkProps {
	className?: string;
	title: string;
	icon: React.ReactNode;
	url?: string;
	onClick?: () => void;
}

export const HomePageLink: React.FunctionComponent<HomePageLinkProps> = ({
	icon,
	title,
	className,
	url,
	onClick,
}) => {
	if (url) {
		return (
			<Link className="link" to={url}>
				<Box
					className={`homePage-link${className ? " " + className : ""}`}
					display="flex"
					alignItems="center"
					flexDirection="column"
				>
					{icon}	
					<Typography className="homePage-link-title" fontFamily="Poppins">
						{title}
					</Typography>
				</Box>
			</Link>
		);
	} else {
		return (
			<Box
				className={`homePage-link link${className ? " " + className : ""}`}
				display="flex"
				alignItems="center"
				flexDirection="column"
				onClick={onClick}
			>
				{icon}
				<Typography className="homePage-link-title" fontFamily="Poppins">
					{title}
				</Typography>
			</Box>
		);
	}
};
