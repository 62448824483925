import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import * as React from "react";
import Texts from "../../resources/Texts";

type DeleteConfirmationDialogProps = {
	itemName: string;
	open: boolean;
	close: () => void;
	onDelete: () => void;
};

const DeleteConfirmationDialog: React.FunctionComponent<DeleteConfirmationDialogProps> = (
	props
) => {
	const handleDelete = (): void => {
		props.onDelete();
		props.close();
	};

	return (
		<Dialog
			maxWidth="lg"
			open={props.open}
			onClose={props.close}
			onClick={(e) => e.stopPropagation()}
		>
			<DialogTitle>{Texts.Dialogs.DeleteConfirmation.Title}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{Texts.Dialogs.DeleteConfirmation.ContextText}
					{props.itemName}?
				</DialogContentText>
			</DialogContent>

			<DialogActions>
				<Button onClick={props.close}>{Texts.Buttons.Cancel}</Button>
				<Button onClick={handleDelete}>{Texts.Buttons.Delete}</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteConfirmationDialog;
