import * as React from "react";
import { Container, Typography } from "@mui/material";
import Texts from "../resources/Texts";

export const LayoutFooter: React.FunctionComponent<{}> = () => {
	return (
		<footer>
			<Container>
				<Typography variant="caption" gutterBottom>
					{Texts.Footer.ConditionsNotice}
				</Typography>
			</Container>
		</footer>
	);
};
