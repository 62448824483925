import * as React from 'react';
import clsx from 'clsx';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CustomTextField from '../CustomTextField';
import CompanyPickerModal from '../CompanyPickerModal';
import { IChannelCompany } from '../../../resources/Contracts';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import NodePickerModal from '../NodePickerModal';
import { Routing } from '../../../resources/Routes';

/// This is a top level component for Add Channel, it will have the states for both Company and Node

export default function AddChannelModal() {
  const [open, setOpen] = React.useState(false);

  // state related to the new channel
  const [channelName, setChannelName] = React.useState("");
  const [channelDescription, setChannelDescription] = React.useState("");
  const [targetNode, setTargetNode] = React.useState(0);  // the id of the node for this channel
  const [targetNodeFriendly, setTargetNodeFriendly] = React.useState("");
  const [targetCompanies, setTargetCompanies] = React.useState<IChannelCompany[]>([]);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [errorText, setErrorText] = React.useState("");

  const apiPostNewChannel = "/api/Channel";
  const apiResolveFriendlyNode = "/api/Channel/navigation/friendlypath";

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    fetch(`${apiResolveFriendlyNode}/${targetNode}`)
      .then((response) => response.text())
      .then((data) => setTargetNodeFriendly(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, [targetNode]);


  const handleAdd = async () => {
    if (channelName === "") {
      setErrorText("Please enter a channel name");
      setErrorOpen(true);
      return;
    }
    if (channelDescription === "") {
      setErrorText("Please enter a channel description");
      setErrorOpen(true);
      return;
    }
    if (targetNodeFriendly === "") {
      setErrorText("Please select a path");
      setErrorOpen(true);
      return;
    }
    if (targetCompanies.length === 0) {
      setErrorText("Please select at least one company");
      setErrorOpen(true);
      return;
    }
    if (channelName !== "" && targetNode !== 0) {
      let formData = new FormData();

      // user is allowed to submit without giving companies access
      if (targetCompanies.length > 0) {
        const companyIdArray = targetCompanies.reduce((acc, obj) => {
          acc.push(obj.id);
          return acc;
        }, []);
        let companyPermissions = JSON.stringify(companyIdArray);
        console.log(companyPermissions);
        formData.append('permissions', companyPermissions);
      }
      formData.append('id', '0');
      formData.append('name', channelName);
      formData.append('description', channelDescription);
      formData.append('navigationId', targetNode.toString());
      formData.append('navigationNode', ' ');
      formData.append('resolvedNavigationNode', ' ');
      formData.append('isEnabled', 'true');
      formData.append('isBlocked', 'false');

      const requestOptions = {
        method: 'POST',
        body: formData
      };
      await fetch(apiPostNewChannel, requestOptions)
          .then(response => response.json())
          .catch((error) => console.error('Error when POSTing data:', error));
      window.location.replace(Routing.getChannelManagementViewUrl());
    }
  }

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorOpen(false);
  };

  const handleCloseReset = () =>
  {
    setTargetNode(0);
    setTargetCompanies([]);
    setOpen(false);
  }

  function handleNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setChannelName(event.target.value);
  }

  function handleDescriptionChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setChannelDescription(event.target.value);
  }

  return (
    <div>
      <Snackbar open={errorOpen}
                autoHideDuration={3000}
                onClose={handleErrorClose}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
        <Alert
            // onClose={handleErrorClose}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
        >
          {errorText}
        </Alert>
      </Snackbar>
      <TriggerButton sx={{ marginRight: 2, marginLeft: '-16px' }} type="button" onClick={handleOpen}>
        Create
      </TriggerButton>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent sx={{ width: 900 }}>
          <h2 id="unstyled-modal-title" className="modal-title">
            Create Download Channel
          </h2>
          <p id="unstyled-modal-description" className="modal-description">
            Define a customer download channel for automated downloads using the Channel API
          </p>
          <CustomTextField changeHandler={handleNameChange} label={"Channel Name"} name={"tbChannelName"}/>
          <CustomTextField changeHandler={handleDescriptionChange} label={"Description"} name={"tbChannelDescription"}/>
          <h3 id="unstyled-modal-target-companies-title" className="modal-description" style={{marginTop: '16px'}}>
            <b>Shared Path</b>
          </h3>
          { targetNodeFriendly !== "" &&
          <Typography>
            {targetNodeFriendly}
          </Typography> }
          <NodePickerModal setTargetNode={setTargetNode} channelSharedPath={targetNodeFriendly}/>
           <h3 id="unstyled-modal-target-companies-title" className="modal-description" style={{marginTop: '16px'}}>
             <b>Permissions</b>
          </h3>
          <CompanyPickerModal channelUniqueId={""} targetCompanies={targetCompanies} setTargetCompanies={setTargetCompanies} />
          <div>
            {targetCompanies.length !== 0 &&
          <TableContainer sx={{ height: 200, marginTop: '16px' }}>
			      <Table width="100%" className="channelCompaniesTable">
              <TableHead>
                <TableRow>
                  <TableCell width="150px" style={{ position: 'sticky', top: 0, zIndex: 1, background: 'powderblue' }}><b>Company</b></TableCell>
                  <TableCell width="50px" style={{ position: 'sticky', top: 0, zIndex: 1, background: 'powderblue' }}><b>Domain</b></TableCell>
                </TableRow>
              </TableHead>
				      <TableBody>
					      {targetCompanies.map((item, id) => (
                  <TableRow key={`channelModifyCompaniesTable-item_${id}`}>
                    <TableCell width="150px">
                      {item.companyName} 
                    </TableCell>
                    <TableCell width="50px">
                      {item.domain }                    
                    </TableCell>
                  </TableRow>
					      ))}
				      </TableBody>
			      </Table>
		      </TableContainer>
            }
          </div>
          <div>
          <TriggerButton type="button" onClick={handleAdd}>
            Add
          </TriggerButton>
          <TriggerButton style={{backgroundColor: 'grey',   border: '1px solid grey', marginLeft: '16px', marginTop: '16px'}} type="button" onClick={handleCloseReset}>
            Cancel
          </TriggerButton>
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'base-Backdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `,
);

const TriggerButton = styled('button')({
  fontFamily: 'IBM Plex Sans, sans-serif',
  fontWeight: 600,
  width: 100,
  fontSize: '0.875rem',
  lineHeight: 1.5,
  padding: '8px 16px',
  transition: 'all 150ms ease',
  cursor: 'pointer',
  background: '#3380FF',
  border: '1px solid #3380FF',
  color: 'white',

  '&:hover': {
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.35)',
  }
});